.how-we-different {
  text-align: center;
}

.ace-case-heading {
  font-size: 40px;
  font-weight: bold;
}

.ace-case-sub-heading {
  font-size: 18px;
  color: #484848;
  font-weight: 400;
}

.how-we-different-txt {
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 2px 30px #00000024;
  margin-top: 20px;
  padding: 25px;
  border-radius: 10px;
}

.upcoming-events-section {
  background-size: cover;
  background-repeat: no-repeat;
}

.upcoming-events-text {
  font-size: 16px;
  color: #636363;
  text-align: justify;
}

.watch-now-btn {
  background: #9d49fc;
  color: #fff !important;
  display: inline-block;
  padding: 10px 25px;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 35px;
}

.modal-body.upcoming-event-modal-video {
  padding: 15px 15px 9px 15px;
}

.upcoming-event-close-btn {
  position: absolute;
  right: -17px;
  background: #9447f9 !important;
  opacity: 1;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: -19px;
}

.upcoming-event-close-btn:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.campus-collage {
  background: #fff;
  box-shadow: 0px 2px 30px #00000029;
  padding: 25px;
  border-left: 5px solid #a249fd;
}

.dark-purple-color {
  color: #8544f5;
}

.logo-box {
  background: #ffff;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 2px 15px #00000017;
  padding: 10px 15px 5px 15px;
  margin-bottom: 30px;
  transition: 0.5s;
}

.logo-box:hover {
  box-shadow: none;
  transition: 0.5s;
  transform: translateY(-5px);
}

.logo-box img {
  /*   width: 100px;
   height: 90px; */
  object-fit: contain;
  padding-top: 8px;
  padding-bottom: 8px;
}

.subs-achievment-section {
  background: #f9f9f9;
}

#acethecase-tab {
  background: transparent;
}

/* #acethecase-tab .nav-tabs .nav-item.show .nav-link,
 .nav-tabs .nav-link.active {
   font-size: 16px;
   background: #5736BA;
   border: 1px solid #5736BA;
   border-radius: 30px;
   font-family: 'Roboto Condensed', sans-serif;
   line-height: 40px;
 } */

/* .nav-item a:before {
   display: none;
 }

 .nav-tabs .nav-link:hover {
   border: 1px solid #fff0;
   transition: 0.5s
 }

 .nav-tabs .nav-link {
   background-color: #fff;
   color: #444;
   font-size: 16px;
   background: #ffffff00;
   border: 1px solid #ffffff00;
   border-radius: 30px;
   line-height: 40px;
   width: auto;
   margin: 0;
   padding: 5px 35px;

 } */

#acethecase-tab.nav-tabs .nav-link {
  height: auto;
}

#acethecase-tab.nav-tabs {
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  background: #efefef;
  padding: 0;
  border-radius: 30px;
}

.acethecase-section .cstm-title {
  display: none;
}

.slick-slide > div {
  margin: 0 15px;
}

.acethecase-section .slick-slide {
  opacity: 0.3;
}

.acethecase-section .slick-slide.slick-current {
  opacity: 1;
}

.acethecase-banner-section {
  background-image: url(../../images/acethecase-banner-right.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #844bf521;
}

.slider-single.active {
  text-align: center;
}
.slider-single.active .register-event {
  background: #9c48fb;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.slick-center.slick-current .register-event {
  opacity: 1;
}

.register-event:hover {
  color: #fff;
}
.acethecase2020 {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  padding-bottom: 100px;
}

.acethecase2020 .slick-dots {
  bottom: -45px;
}

.acethecase2020 .slick-arrow {
  display: none !important;
}

.acethecase2020 .slick-dots li.slick-active button:before {
  color: #ffffff !important;
}

.acethecase2020 .ace-case-heading,
.acethecase2020 h6 {
  color: #fff !important;
}
.acethecase2020 .slick-slide img {
  border: 4px solid #fff;
  box-shadow: 0px 2px 20px #0000002b;
}

@media screen and (max-width: 480px) {
  #acethecase-tab.nav-tabs {
    background: transparent;
  }
  .slick-next {
    right: 0;
  }
  .our-story-section {
    padding-bottom: 50px !important;
  }

  .aceTheCase-slider2021
    .react-3d-carousel
    .slider-container
    .slider-right
    div {
    margin-left: 17px;
  }
  .aceTheCase-slider2021 .react-3d-carousel .slider-container .slider-left div {
    left: -33%;
  }
  .slider-single.active .register-event {
    font-size: 16px;
    padding: 7px 14px;
  }

  #acethecase-tab.nav-tabs li:last-child {
    border: 1px solid #dbdbdb;
    margin-top: 15px;
    border-radius: 30px;
  }

  .acethecase-banner-section {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .our-story-section iframe {
    height: 300px;
  }
}

@media screen and (min-width: 481px) {
  .grad-ward-section {
    background-image: url("../../images/grad-ward-bg.png");
    background-repeat: no-repeat;
    padding-bottom: 60px;
    background-size: contain;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .acethecase-banner-section {
    background-size: 500%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .our-story-section iframe {
    height: 300px;
  }
  .our-story-section {
    padding-bottom: 50px !important;
  }
}

@media screen and (min-width: 768px) {
  .acethecase-section .slick-slide img {
    width: 300px !important;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
  }
  .past-workshops {
    margin-left: 70px !important;
  }

  .aceTheCase-slider2021 {
    margin-bottom: 60px;
  }

  .upcoming-events-section {
    background-image: url("../../images/video-bg.jpg");
  }

  .acethecase-section .slick-slide.slick-current img {
    width: 100% !important;
    margin-top: 0px;
  }

  .aceTheCase-slider2021 .react-3d-carousel,
  .aceTheCase-slider2021 .react-3d-carousel img {
    height: 500px;
  }
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-single-content {
  box-shadow: none !important;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .aceTheCase-slider2021 {
    margin-top: 40px;
  }
  .ace-case-heading {
    margin-top: 0px !important;
  }
  .acethecase-section .slick-slide img {
    width: 150px !important;
    margin-top: 20px;
  }

  .acethecase-banner-section {
    background-size: 149% 86%;
    background-position: right;
  }

  .aceTheCase-slider2021 .slick-initialized .slick-slide img {
    height: 150px;
    opacity: 0.2;
  }

  .aceTheCase-slider2021 .slick-center.slick-current img {
    height: 300px;
    opacity: 1 !important;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .acethecase-section .slick-slide img {
    width: 200px !important;
    margin-top: 30px;
  }

  .acethecase-banner-section {
    background-size: 149% 86%;
    background-position: right;
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .aceTheCase-slider2021 {
    margin-top: 20px;
  }
  .ace-case-heading {
    font-size: 26px;
    font-weight: bold;
    margin-top: 0 !important;
  }

  .aceTheCase-slider2021 {
    margin-bottom: 40px;
  }

  .register-event {
    font-size: 15px;
  }

  .aceTheCase-slider2021 .slick-initialized .slick-slide img {
    height: 100px;
    opacity: 0.2;
  }

  .aceTheCase-slider2021 .slick-center.slick-current img {
    height: 200px;
    opacity: 1 !important;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 1023px) {
  .acethecase-section .slick-slide.slick-current img {
    width: 100% !important;
    margin-top: 0px;
  }

  .acethecase-banner-section {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 60px;
    background-position: right;
  }

  .aceTheCase-slider2021 .slick-initialized .slick-slide img {
    height: 300px;
    opacity: 0.2;
  }

  .aceTheCase-slider2021 .slick-center.slick-current img {
    height: 500px;
    opacity: 1 !important;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1023px) {
  .acethecase2020 {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 1198px) {
  .upcoming-events-section {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .video-img-width {
    width: 100%;
  }
}

@media screen and (min-width: 1199px) {
  .video-img-width {
    width: 871px;
  }

  .desktop-pt-150 {
    padding-top: 150px;
  }

  .desktop-pt-100 {
    padding-top: 100px;
  }
}

.about-us-txt {
  background: #e0fcfe;
  display: inline-block;
  padding: 8px 20px;
  font-size: 20px;
  color: #000 !important;
  font-weight: 500;
  border-radius: 5px;
}

.acethecase-banner-heading {
  font-size: 60px;
  font-weight: 900;
}

.case-txt {
  -webkit-text-stroke: 2px #9d49fc;
  -webkit-text-fill-color: transparent;
}
/*
 .subscribe-heading{
  background: linear-gradient(
    180deg
     , #7942f3 -12.5%, #af4bff 131.82%), #C4C4C4;
        color: #fff;
        padding: 30px 0;
 } */

/* h3 {
    background: #00558B;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
  }
  .variable-width .slick-slide p {
    background: #00558B;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
  }
  .center .slick-center img {
    color: #e67e22;
    opacity: 1;
    transform: scale(2.00);
  }
  .center img{
    opacity: 0.8;
    transition: all 300ms ease;
  }
  .content {
    padding: 20px;
    margin: auto;
  }
  @media (min-width: 701px) {
    .content {
      width: 80%;
    }
  }
  @media (max-width: 700px) {
    .content {
      width: 70%;
    }
  }
  .slick-slide .image {
    padding: 10px;
  }
  .slick-slide img {
    border: 5px solid #FFF;
    display: block;
    margin: auto;
    max-width: 80%;
  }
  .slick-slide img.slick-loading {
    border: 0
  }
  .slick-slider {
    margin: 30px auto 50px;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
  }
  .slick-thumb li.slick-active img{
      filter: grayscale(0%);
  }
  @media (max-width: 768px) {
    h3 {
      font-size:24px;
    }
    .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .center .slick-center h3 {
      color: #e67e22;
      opacity: 1;
      transform: scale(1);
    }
    .center h3 {
      opacity: 0.8;
      transform: scale(0.95);
      transition: all 300ms ease;
    }
  }
  .slick-vertical .slick-slide {
    height: 180px;
  }
  .slick-arrow {
    background-color: grey;
  }
  .slick-arrow:hover {
    background-color: grey;
  }
  .slick-arrow:focus {
    background-color: grey;
  }
  .button {
    background-color: #00558B;
    padding: 10px 20px;
    margin: 0px 20px;
    border: none;
    color: white;
    font-size: 20px;
    border-radius: 5px;
    min-height: 45px
  }
   */

.our-story-section {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 55%,
    rgba(241, 241, 241, 1) 55%
  );
  padding-bottom: 90px;
}

.aceTheCase-slider2021 .react-3d-carousel .slider-container .slider-right div,
.aceTheCase-slider2021 .react-3d-carousel .slider-container .slider-left div {
  border: 1px solid #7f44f5 !important;
  background: #7e43f4 !important;
}

.aceThecase-campus-slider {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 37%,
    rgba(239, 232, 254, 1) 37%
  );
  padding-top: 80px;
  margin-top: 30px;
  margin-bottom: 90px;
}

.grad-ward-section img {
  margin: 0 auto;
}

@media screen and (min-width: 1199px) {
  .grad-ward-section .slick-slider::before {
    content: url("../../images/after\ before\ img.png");
    position: absolute;
    z-index: -1;
    bottom: -50px;
    bottom: -100px;
    margin-left: 95px;
  }
  .grad-ward-section .slick-slider::after {
    content: url("../../images/after\ before\ img.png");
    position: absolute;
    z-index: -1;
    top: -152px;
    right: 74px;
    bottom: -100px;
    margin-left: 95px;
  }
}

/* aftab file */

/* Chalkduster Font includes */
@font-face {
  font-family: "Chalkduster";
  src: url(./fonts/Chalkduster.eot);
  src:
    url(./fonts/Chalkduster.eot?#iefix) format("embedded-opentype"),
    url(./fonts/Chalkduster.woff2) format("woff2"),
    url(./fonts/Chalkduster.woff) format("woff"),
    url(./fonts/Chalkduster.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h2 {
  font-weight: 700;
}

h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

section {
  overflow: hidden;
}

/* Header Style */
.styledFont {
  font-family: "Chalkduster";
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 67px;
  color: #18181b;
}

.colorOrange {
  color: #ff283a;
}

.banner-info-box p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
}

.buttonMain {
  background: #ff283a;
  align-items: center;
  padding: 8px 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
}

.flag-bg {
  background-image: url(img/Ill-Backdrop_Line.svg);
  background-repeat: no-repeat;
  background-position-x: 72%;
  background-position-y: 35%;
  background-size: 85%;
}

.flag {
  text-align: right;
  margin-top: -70px;
  margin-right: 50px;
}

.arrow-row {
  margin-top: -210px;
  margin-bottom: 100px;
  text-align: center;
}

.elm-card {
  border-radius: 18px;
}

.elm-card.color5 {
  background: #e6f6f5;
}

.elm-card.color6 {
  background: #e6e9fa;
}

.elm-card.color7 {
  background: #fcf6f0;
}
/* Workshops */
#workshops {
  padding: 75px 0 270px;
  background: #eff3f7;
  /* margin-top: -135px; */
}

.workshop-image img {
  border-radius: 10px;
  border: 2px solid;
  width: 100%;
}

.workshop-content p {
  font-size: 15px;
  color: #788791;
  line-height: 1.3;
}

.workshop-meta p {
  font-size: 15px;
  font-weight: 600;
}

.workshops .swiper-pagination {
  left: -4.5% !important;
}

.swiper .swiper-pagination {
  bottom: -10px !important;
  width: auto !important;
  display: inline-block;
  position: relative;
}

.swiper-btn-prev,
.swiper-btn-next {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  top: 15px;
}

.swiper-btn-next {
  left: -4%;
}

.swiper-btn-prev {
  left: -5%;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 24px;
  border-radius: 0;
  height: 2px;
}

.swiper-pagination-bullet-active {
  background: #111111;
}

/* Past Workshop Style */
#past-workshops {
  margin-top: -200px;
}

.past-workshop-wrapper {
  background: #0e0e29;
  border-radius: 15px;
}

.past-workshop-info-box {
  background: #000000b5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.past-workshops .swiper-btn-prev,
.past-workshops .swiper-btn-next {
  left: -25px;
  top: 13px;
}

.past-workshops.swiper .swiper-pagination {
  bottom: -11px;
  left: -2.3%;
}

.past-workshops .swiper-pagination .swiper-pagination-bullet {
  width: 24px;
  border-radius: 0;
  height: 2px;
  background: #fff;
}

.past-workshops img,
.past-workshops .past-workshop-info-box {
  border-radius: 10px;
  width: 100%;
}

.past-workshop-info-box {
  align-items: center;
  display: none;
}

.past-workshops .swiper-slide:hover .past-workshop-info-box {
  display: inline-grid;
}

.past-workshop-info-box .buttonMain {
  margin: 0 auto;
  position: relative;
  color: #fff;
}

.past-workshop-info-box::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 95%;
  height: 90%;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin: 10px;
}

/* Campus */

#campus {
  padding: 150px 0 200px;
  background: url(img/Ill-AceTheCase_Campus.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 93% 5px;
  margin-top: -80px;
  border-bottom: 2px solid #e4e1e1;
}

.campus-title h2 span {
  color: #5a0162;
}

.campus-title p {
  font-size: 15px;
  color: #788791;
  line-height: 1.3;
}

.campus-info-box {
  background: #000000b5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.campus .swiper-btn-prev,
.campus .swiper-btn-next {
  left: 0;
}

.campus .swiper .swiper-pagination {
  bottom: 0;
}

.campus img,
.campus .campus-info-box {
  border-radius: 10px;
  width: 100%;
}

.campus img {
  box-shadow: 15px 15px 0px 0px #dee6ee;
}

.campus-info-box {
  align-items: center;
  display: none;
}

.campus .swiper-slide:hover .campus-info-box {
  display: inline-grid;
}

.campus-info-box .buttonMain {
  margin: 0 auto;
  position: relative;
  color: #fff;
}

.campus-info-box::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 94%;
  height: 94%;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin: 10px;
}

/* Our Story */
#ourStory .player {
  position: relative;
  border-radius: 15px;
}

#ourSotry .color2 {
  color: #ff0266;
}

.video-thumbnail {
  position: absolute;
  left: 0;
  top: 0;
}

/* Testimonials */
#testimonials .subtitle {
  color: #51636f;
}

.testimonial-thumbnail {
  border-radius: 8px;
}

.testimonial-title {
  padding: 5px 10px 9px;
  border: 1px solid #000;
  display: block;
  border-radius: 8px;
  background: #fff;
  margin-top: -80px;
  position: relative;
  font-size: 10px;
  box-shadow: -7px 7px 0 0 #ff0266;
}

.testimonial-title .t-title {
  font-size: 12px;
  line-height: 1.3;
}

.testimonial-content-box {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}

.testimonial-content-box::before {
  content: "";
  display: block;
  background: url(img/quote-left.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 52px;
  height: 50px;
}

.testimonial-content-box::after {
  content: "";
  display: block;
  background: url(img/quote-right.svg);
  background-size: 100%;
  width: 52px;
  height: 56px;
  background-repeat: no-repeat;
  float: right;
  margin-top: 0px;
  margin-right: 16px;
}

.testimonial-bg::before {
  content: "";
  display: block;
  position: absolute;
  background: #f5f5fa;
  height: 90%;
  width: 121%;
  z-index: -1;
  left: -21%;
  top: 0px;
  border-radius: 12px;
}

#testimonials .swiper-btn-prev,
#testimonials .swiper-btn-next {
  left: 0;
}

/* Subscribers Achievements */
#achievements .color2 {
  color: #5a0162;
  font-style: italic;
}

#achievements .list-group li {
  list-style: none;
  background: #ffffff;
  box-shadow: 6px 7px 22px rgba(33, 38, 106, 0.15);
  border-radius: 10px;
  margin: 10px;
}

.marquee img {
  background: #ffffff;
  box-shadow: 6px 7px 22px rgba(33, 38, 106, 0.15);
  border-radius: 10px;
  margin: 10px;
}

.marquee-left__wrapper,
.marquee-right__wrapper {
  display: flex;
  transition-timing-function: linear !important;
  color: white;
}

.marquee-left__slide,
.marquee-right__slide {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background: #ffffff;
  box-shadow: 6px 7px 22px rgba(33, 38, 106, 0.15);
  border-radius: 10px;
}

.subscriber-achievement {
  overflow: hidden;
}

/* Awards */
#awards .color2 {
  color: #ff0266;
  font-style: italic;
}

.awards-thumbnail img {
  border-radius: 8px;
}

.awards-title {
  padding: 5px 10px 9px;
  border: 1px solid #000;
  display: block;
  border-radius: 8px;
  background: #fff;
  margin-top: -40px;
  position: relative;
  font-size: 10px;
  box-shadow: -7px 7px 0 0 #ff0266;
  width: 80%;
  float: right;
}

.awards-title .t-title {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 600;
}

.swiper-slide .awards-inner-wrapper {
  opacity: 0.4;
  width: 90%;
  margin-top: -25px;
}

.swiper-slide.swiper-slide-active .awards-inner-wrapper {
  opacity: 1;
  width: 100%;
  margin-top: 0;
}

#awards .swiper-btn-prev,
#awards .swiper-btn-next {
  left: 0;
}

.swiper-slide .award-thumb::before {
  content: "";
  display: block;
  background: #e7f7f6;
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: -1;
  left: 0px;
  top: -40px;
  border-radius: 20px;
}

.swiper-slide.swiper-slide-active .award-thumb::before {
  content: "";
  display: block;
  background: #fef2eb;
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: -1;
  right: -10px;
  bottom: 30px;
  border-radius: 20px;
  left: inherit;
  top: inherit;
}

.awards-meta {
  position: absolute;
  top: -17px;
  background: #ff283a;
  width: 32px;
  height: 32px;
  border-radius: 35px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  border: 1px solid #000;
  right: 10px;
}

/* Footer Styling */
/* .footer {
  background: #111111;
  overflow: hidden;
} */

@media only screen and (max-width: 767px) {
  .styledFont {
    font-size: 40px;
    line-height: 45px;
  }

  .flag {
    display: none;
  }

  #workshops {
    padding: 75px 20px 270px;
  }

  .past-workshop-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }

  .past-workshops.swiper .swiper-pagination,
  .past-workshops .swiper-btn-prev,
  .past-workshops .swiper-btn-next {
    left: 0;
  }

  .testimonial-content-box {
    padding: 30px;
  }

  .testimonial-bg::before {
    height: 100%;
    width: 100%;
    z-index: -1;
    left: 0;
    top: inherit;
    border-radius: 12px;
  }

  .testimonial-content-box::before,
  .testimonial-content-box::after {
    width: 50px;
    height: 35px;
  }

  .footer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.buttonMain > svg {
  font-size: 12px;
}
.wave-hand {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 29.7%;
  left: 83%;
  transform: translate(-50%, -50%);
}
.swiper-pagination {
  margin-right: 12px;
  margin-left: 12px;
}
html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
