.addquizsection {
  padding: 40px 100px 1px 100px;
}
.quizdetailmsg span {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
}
.quizdetailmsg li {
  color: #333;
  list-style: disc;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.uploader label.quizlable {
  float: left;
  border: none;
  clear: both;
  width: 100%;
  padding: 30px 0px 6px 0px;
  text-align: center;
  border-radius: 7px;
  transition: all 0.2s ease;
  user-select: none;
}
.quizform .alert {
  color: #191f23;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-left: 0px;
  line-height: 20px; /* 125% */
}
.p-3.transparentbtn {
  border-radius: 8px;
  border: 2px solid #ff283a;
  padding: 16px 28px 16px 28px !important;
}
.ant-select-arrow .anticon svg {
  fill: #000 !important;
}
.form-control.redborder {
  border: 1px solid #ed3833 !important;
}
