.ant-collapse {
  background-color: #eff4f9 !important;
}
.ant-collapse-item {
  margin-bottom: 24px;
  background: #fff;
  border-radius: 8px;
  border: none;
}

.ant-collapse-header-text:before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 19px 16px;
  border-bottom: 1px solid #e6e6e6;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition:
    all 0.3s,
    visibility 0s;

}

.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  margin-bottom: 18px;
} 
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  flex: auto;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.importantField {
  color: red;
}
.emailsec {
  background: #f5f5f5;
  border-radius: 12px;
  padding: 16px;
}

.emailsec .form-control {
  background: rgb(255 255 255) !important;
  border: none !important;
  font-size: 16px !important;
  border: 1px solid #dbdbdb !important;
  color: #333333;
  font-weight: 400 !important;
  padding: 10px;
}
.ant-collapse-content-box .anticon svg {
  font-size: 12px;
  fill: #fff;
  display: none;
}
.dropdownshow {
  position: absolute;
  right: 29px;
  top: 44px;
  /* z-index: 99; */
  font-size: 20px;
}

.ant-select-selection-overflow {
  z-index: 9;
}
.participantslist {
  position: absolute;
  top: 43px;
  right: 24px;
  color: red;
  font-size: 14px;
  cursor: pointer;
  z-index: 99;
}
.broadcatmsg {
  margin-bottom: 70px !important;
}
.participantlist {
  width: 800px !important;
}
.broadcastmailslist {
  display: flex;
  padding: 12px;
  border: 1px solid #d9d9d9;
}
.broadcastmails {
  position: relative;
  display: flex;
  flex: none;
  padding: 7px 8px 6px 8px;
  box-sizing: border-box;
  max-width: 100%;
  border-radius: 5px;
  height: auto;
  background: #ff283a33;
  color: #ff2f3b;
  margin-right: 20px;
}
