/* Basic modal styling */
.festival-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.festival-modal-content {
  background: #fff;
  border-radius: 10px;
  padding: 2rem;
  width: 60%;
  max-width: 90%;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.festival-close-modal-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.festival-modal-content h3 {
  margin-bottom: 1.5rem;
  color: #333;
}

.festival-modal-scrollable-content {
  max-height: 70vh;
  overflow-y: auto;
  margin-bottom: 15px;
  padding-right: 10px;
}

/* Form styling */
.festival-form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.festival-form-row > *:only-child {
  width: 100%;
}

.festival-form-row > * {
  width: 48%;
}

.festival-form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.festival-modal-content label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #51636f;
}

.festival-modal-content input,
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
}

.festival-modal-content input:focus,
textarea:focus {
  border-color: #007bff;
}

.festival-modal-content textarea {
  resize: none;
}

.festival-submit-btn {
  margin-left: 5px;
}

.festival-form-group.eligibility {
  .ant-input::placeholder {
    color: rgb(133, 133, 133);
  }

  .opportunity-lists {
    flex-wrap: wrap;
    flex-shrink: 0;
    margin-top: 0px;
  }

  .opportunity-item {
    padding: 3px 5px;
    font-size: 14px;
  }
}

.festival-form-group .ck-editor__editable {
  min-height: 150px;
  max-height: auto;
  overflow: auto;
}

.festival-image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.festival-upload-preview {
  display: block;
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
  border: 1px solid #b50505;
  cursor: pointer;
}

.festival-multiple-image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.festival-image-remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.festival-image-remove-button:hover {
  background: darkred;
}

.image-uploading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px !important;

  .spinner-border {
    margin-bottom: 0px !important;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .festival-modal-content {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .festival-modal-content {
    width: 90%;
  }
}

/* Style the scrollbar for WebKit browsers */
.festival-modal-scrollable-content::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

.festival-modal-scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Optional: round edges */
}

.festival-modal-scrollable-content::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: round edges */
  border: 2px solid #f1f1f1; /* Optional: padding around thumb */
}

.festival-modal-scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change color on hover */
}

/* Style for Firefox */
.festival-modal-scrollable-content {
  scrollbar-color: #888 #f1f1f1; /* thumb color, track color */
  scrollbar-width: thin; /* Choose between auto, thin, and none */
}
