.about-us-content {
  background-color: #f5f5f5;
}
.about-head {
  background-image: url("../../images/about-us-head.png");
  background-repeat: no-repeat;
  padding-top: 200px;
  margin-bottom: -20px;
}
.about-tabs {
  margin: 0px auto;
  border-radius: 50px;
  text-align: center;
  position: relative;
  top: -10px;
}
.about-us-heading {
  /* padding-left: 20px; */
  padding-top: 15px;
}
.about-upper {
  background-image: url("../../images/about-head.jpg");
  background-repeat: no-repeat;
  border-radius: 20px;
}
.breadcrumb-sec {
  background: #fff;
  display: inline-block;
  box-shadow: 0px 2px 15px #0000000d;
  padding: 15px 35px;
  border-radius: 50px;
  font-size: 16px;
}
.breadcrumb-sec a:not(:last-child),
.breadcrumb-sec span {
  color: #c0c0c0;
}
.breadcrumb-sec a:last-child {
  color: #5734ba;
  font-weight: 600;
}
.breadcrumb-sec span {
  padding-left: 7px;
  padding-right: 7px;
}
.about-title h2 {
  color: #5734ba;
}
.about-lower Card {
  box-shadow: 0px 2px 40px 10px rgba(0, 0, 0, 0.08);
}
.we-do {
  text-align: center;
}
.we-do h3 {
  color: #5734ba;
}
.we-do-content {
  border-radius: 30px;
  /* border: 1px solid #dddddd !important; */
  /* border-bottom: 10px #5734ba solid !important; */
  margin-bottom: 10px;
}
.we-do-content h5 {
  margin-top: 20px;
}
.we-do-content Button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.we-do-content Button svg {
  margin: -5px;
}

.we-do-content img {
  width: 50px;
}
.who-we {
  /* background-image: url("../../images/who-we-are-bg.jpg");/ */
  /* background-repeat: no-repeat; */
  /* text-align: center; */
  padding: 152px 20px 80px 20px;
  background-color: #e5e5e5;
  background-size: cover;
}
.who-we-content {
  width: 70%;
  margin: 0 auto;
  border-radius: 30px;
  border: 1px solid #fff;
  padding: 0px 40px;
  margin-top: 60px;
}
.who-we-content h4 {
  color: #ffff;
  font-size: 30px;
  background: #5836ba;
  margin: -20px 12px 29px 39%;
  width: 220px;
}
.who-we-content p {
  color: #ffffff !important;
  text-align: justify;
  font-size: 18px;
  line-height: 32px;
}
.about-founders {
  margin-bottom: 40px;
}

.founder-content {
  background: #fff;

  box-shadow: 0px 2px 15px #0000000d;
}
.founder-txt {
  padding: 25px 25px 0;
}
.founder-modal .modal-body {
  font-size: 16px;
  line-height: 30px;
}

.founders-heading {
  text-align: center;
  margin: 40px 0px;
}
.founders-heading h3 {
  color: #5734ba;
}
.founders-details {
  box-shadow: 0px 2px 30px rgb(0 0 0 / 6%);
  padding: 4px 19px 13px 61px !important;
}
.founder-link a {
  width: 32.9%;
  display: inline-block;
  text-align: center;
  background: #a436fb;
  padding: 12px;
}
.founder-link a:hover {
  background: #000;
}
.founder-link a,
.founder-link i {
  color: #fff;
}
.ameer-iqbal {
  background: whitesmoke;
}
/* .founder-link {    background: #a436fb; padding: 10px 25px;} */
.founder-txt p {
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
}
.founder-section {
  background: #fff;
  box-shadow: 0px 2px 20px #00000014;
}
.founder-section p {
  font-size: 16px;
  line-height: 30px;
}
.founder-section .col-md-6 {
  padding-top: 40px;
  padding-bottom: 30px;
}
/* .ayshman-sikha{border-bottom-right-radius: 20px; border-top-right-radius: 0px;} */
.read-more-founder i {
  background: #5834ba;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
}
.read-more-founder i:hover {
  background: #000;
}
.read-more-founder {
  border-top: 1px dashed #dbdbdb;
  padding-top: 25px;
}
.read-more-founder a {
  margin-left: 5px;
  margin-right: 5px;
}
.founder-section h4 {
  background: linear-gradient(
    179.54deg,
    #5734ba -2.74%,
    #882ad4 51.19%,
    #a142ec 107.38%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #00000024;
}

.members-box {
  box-shadow: 0px 2px 15px #00000014;
  padding: 20px 14px 20px 14px;
  background-image: linear-gradient(
    15deg,
    rgba(255, 255, 255, 1) 63%,
    rgb(241 251 255) 63%
  );
  text-align: center;
  transition: 0.5s;
  border-radius: 8px;
  margin-bottom: 30px;
}

.members-box:hover {
  transform: translateY(-10px);
  transition: 0.5s;
  box-shadow: none;
  background-color: #5834ba;
  background-image: linear-gradient(
    15deg,
    rgb(88 52 186) 63%,
    rgb(98 61 197) 63%
  );
}
.members-box:hover h4,
.members-box:hover h5,
.members-box:hover p,
.members-box:hover a {
  color: #fff !important;
}
.members-box img {
  width: 130px;
  border-radius: 50%;
}
.member-social i {
  font-size: 18px;
  position: relative;
  top: 1px;
}

.about-team-title {
  text-align: center;
  margin: 40px 0px;
}
.about-team-title h3 {
  color: #5734ba;
}

.social-links {
  display: flex;
  padding: 0px;
}
.teamimg {
  background: #fff;
  text-align: center;
}
.teamimg img {
  text-align: center;
  width: 45%;
}
.ant-tabs-nav {
  margin: 0 auto !important;
}
.ant-card-bordered {
  top: 15px;
  position: relative;
  line-height: 25px;
  text-align: left;
  line-height: 30px;
  /* height: 610px; */
  font-size: 16px;
}

.ant-card-bordered.aboutsec {
  /* left: -71px; */
  /* box-shadow: 0px 2px 20px #0000001f; */
  border: none !important;
  background: transparent !important;
}
.roundedimg {
  border-radius: 1.25em;
  margin-top: 50px;
}

.about-lower h2 {
  color: rgb(87 54 186);
}

.about-lower h2 {
  display: flex;
  flex-direction: row;
}
.about-lower h2:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.about-lower h2:before {
  margin-right: 10px;
}
.about-lower h2:after {
  margin-left: 13px;
  margin-top: 29px;
  margin-right: 13%;
}
.ant-card-body {
  padding: 0 !important;
}
/* .ant-card-body:hover
  {
background-color: #5734BA;
color: #fff;
  } */
/* .ant-card.ant-card-bordered.we-do-content .ant-card-body p:hover
  {
    color: #fff !important;

  }
  .ant-card.ant-card-bordered.we-do-content .ant-card-body h5:hover
  {
    color: #fff !important;

  } */

.founders-image img {
  position: absolute;
  z-index: 99;
  border: 5px solid #b8b7b7;
  border-radius: 50%;
  top: 120px;
  left: -84px;
}
.founders-details .and-card-body {
  padding: 26px 19px 13px 92px !important;
}
.ant-tabs-nav {
  margin: auto;
}
.contentcntr {
  justify-content: center;
}
.aboutsec p {
  font-size: 14px;
}

.whatwedobox .ant-card-bordered {
  margin-bottom: 40px;
  padding: 40px;
  background: #efefef;
  height: 375px;
}

@media screen and (max-width: 767px) {
  .ant-card-bordered.aboutsec {
    left: 0;
  }
  .aboutsec {
    height: auto !important;
  }
  .who-we {
    background-image: none;
    padding: 25px 0px 50px 0px;
    background-color: #5834ba;
    background-size: cover;
    margin-top: 60px;
  }
  .who-we-content {
    width: 90%;
    border-radius: 30px;
    border: 1px solid #fff;
    padding: 0px 17px;
    margin-top: 40px;
  }
  .who-we-content h4 {
    margin: -20px 12px 29px 15%;
    width: 200px;
  }
  .who-we-content p {
    line-height: 28px;
  }
  .about-us-section {
    margin-top: 20px;
  }
  .whatwedobox .ant-card-bordered {
    height: auto;
    margin-bottom: 30px;
  }
  .we-do {
    padding-top: 40px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .who-we-content h4 {
    margin: -20px 12px 29px 30%;
    width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .about-us-heading {
    text-align: center;
  }
  .ant-card-bordered.aboutsec {
    left: 0;
  }
  .who-we {
    background-image: none;
    margin-top: 70px;
    padding: 70px 0px 70px 0px;
    background-color: #5834ba;
  }
  .who-we-content {
    width: 87%;
    padding: 0px 30px;
    margin-top: 0px;
  }
  .who-we-content h4 {
    margin: -20px 12px 29px 33%;
  }
  .ipad-half-width {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-card-bordered {
    height: auto;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .nav-link {
    margin-right: 3px;
    font-size: 16px;
  }
  .navbar-brand {
    margin-right: 1rem !important;
  }
  .topsearch {
    width: 170px;
  }
  .nav-right-social a {
    margin: 0 6px !important;
  }
  .menu-active.nav-link {
    font-size: 16px;
  }
  .btn.menubtn {
    font-size: 14px;
  }
  .giflogo {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .desktop-height-505 .we-do-content {
    /* height: 505px !important; */
  }
}

@media screen and (min-width: 1200px) {
  .aboutsec {
    height: auto !important;
  }
}

.aboutuscontainer {
  padding: 0px 50px 81px 60px;
  border-bottom: 1px solid #949494;
  margin-top: 70px !important;
  background-image: url("../../images/aboutusbg.png");
  background-repeat: no-repeat;

  /* background: linear-gradient(90deg, rgba(9,179,236,0.38559173669467783) 0%, rgba(190,225,230,0.2847514005602241) 10%); */
}
.about-us-heading h4 {
  color: #ff283a;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
}

.hr-lines {
  position: relative;
}

.hr-lines:after {
  content: " ";
  height: 2px;
  width: 330px;
  background: linear-gradient(
    90deg,
    rgba(26, 26, 31, 1) 0%,
    rgba(11, 11, 11, 0.23713235294117652) 100%
  );
  display: block;
  position: absolute;
  top: 50%;
  left: 154px;
}
.about-title {
  font-size: 28px !important;
  font-weight: 500;
  color: #000 !important;
  line-height: normal;
  text-align: left;
  margin-bottom: 12px;
}
.aboutussecondcontainer {
  padding: 60px 150px 10px 160px;
}
.aboutussecondcontainer.subscribersecabout {
  padding: 1px 150px 10px 160px !important;
}
.countersec .ant-card-body {
  margin-bottom: 50px;
}
.karpediemspan {
  color: #ccc;
}
.secthird {
  padding: 53px;
  background: #eeeeee;
  text-align: center;
  margin: 12px 100px 2px;
  border-radius: 20px;
}
.secthird p {
  font-size: 15px;
  margin-bottom: 0px !important;
}
.lastsection .about-us-heading {
  text-align: center;
}
.lastsection .about-title {
  margin-top: 10px;
  text-align: center;
}
.whatwedobox {
  margin-bottom: 2px;
}
.we-do-content .ant-card-body p {
  line-height: 18px !important;
  font-size: 12px;
}
