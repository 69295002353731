.toprow {
  padding: 15px;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 20px;
}
.dashboardsecondrow {
  padding: 25px;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 20px;
}

.sidemenu {
  background-color: #fff;
}

.sidebarmenu {
  padding: 10px;
}
body {
  background-color: #eff4f9;
}
.sidebarmenu li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 45px;
  /* identical to box height, or 160% */
  padding-left: 12px;

  color: #51636f;
}
.left_sidebar .nav_items .leftsidebar_item {
  margin-left: 25px;
  margin-right: 25px;
}

.left_sidebar .nav_items .leftsidebar_item.active {
  background: rgba(255, 40, 58, 0.2);
  border-radius: 8px;
  padding-left: 15px;
  color: #ff283a;
  padding-bottom: 10px;
  padding-top: 10px;
}

.firstHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 117% */

  color: #18181b;
}

.secondHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #219653;
}

.col-md-3 .toprow {
  display: flex;
}

.col-md-3 .toprow .imgeshow {
  padding-top: 4px;
}

.col-md-3 .toprow .imgeshow .list-icon-img {
  width: 80%;
}

.maintopbox {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #51636f;
}
.topRowbox {
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  /* identical to box height, or 133% */
  /* padding-top: 5px; */
  display: inline-block;
  /* display: contents;. */
  color: #18181b;
}

.topRowbox span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: contents;

  color: #219653;
}
.recharts-layer.recharts-cartesian-axis-tick {
  font-size: 12px;
}
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");

/* body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cairo', sans-serif;
} */

* {
  letter-spacing: 0.1 px;
}

a {
  text-decoration: none;
}

.wrapper {
  display: flex;
}

.left_sidebar {
  width: 280px;
  background-color: #fff;
  position: fixed;
  height: 100vh;
  transition: 0.5s;
  overflow-x: hidden;
  border-right: 1px solid #ddd;
}

/* body::-webkit-scrollbar {
    width: 10px;
} */

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--navyBlue);
  outline: 1px solid slategrey;
}

/* .left_sidebar::-webkit-scrollbar {
    width: 5px;
} */

.left_sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.left_sidebar::-webkit-scrollbar-thumb {
  background-color: #2a3e64;
  outline: 1px solid slategrey;
}

.contents {
  width: 100%;
  margin-left: 280px;
  transition: 0.5s;
  background-color: #eff4f9;
}

.left_sidebar_shrink {
  width: 106px;
  transition: 0.5s;
}

.content_full_width {
  margin-left: 106px;
  transition: 0.5s;
}

.left_sidebar_shrink .hide_on_shrink {
  display: none;
}

.page-title {
  font-size: 27px;
  font-weight: bold;
}

.color-white {
  color: var(--whiteColor);
}

@media screen and (max-width: 767px) {
  .page_content {
    padding: 0 10px;
  }
}

@media screen and (min-width: 768px) {
  .page_content {
    padding: 0 20px;
  }
}
.footer {
  display: none;
}
.topheading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;
  /* identical to box height, or 140% */

  color: #51636f;
}
.list-icon-img-d {
  width: 50px;
}
@media (min-width: 1024px) {
  .contents .page_content .container {
    max-width: 100% !important;
  }
}
.input-titlle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-transform: capitalize;
  /* identical to box height, or 114% */

  color: #51636f;
}
.opportunityDashboard .input-titlle {
  font-size: 12px !important;
}
.dashboardsecondrow .stat {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.domain .form-control .form-check .form-check-label,
.domain .input-titlle,
.domain .twocolform .input-titlle {
  font-size: 14px !important;
}
.filterbtn {
  color: var(--primaryColor);
  cursor: pointer;
}
.dotcss {
  background: #4f5e74;
  height: 50px;
  color: #fff;
  padding: 0px 8px 9px 9px;
  border-radius: 50%;
}
.imgeshow .recharts-wrapper svg {
  border-radius: 100%;
}
.imgeshow .recharts-wrapper {
  position: relative;
  cursor: default;
  width: 40px;
  margin-right: 14px;
  height: 40px;
  margin-top: 3px;
}
.dot {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}
.approved {
  background-color: #4a3aff;
}
.underreivew {
  background-color: #962dff;
}
.Pending {
  background-color: #e0c6fd;
}
.Rejected {
  background-color: #c6d2fd;
}
.dashboardpopup.modal.show .modal-dialog {
  max-width: 700px;
}
.dashboardpopup.modal.show .modal-dialog .modal-content {
  padding: 28px;
  border-radius: 12px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  padding: 7px 8px 6px 8px;
  box-sizing: border-box;
  max-width: 100%;
  border-radius: 5px;
  height: auto;
  background: #ff283a33;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  color: #ff283a;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  border-radius: 50%;
  background: #ff283a;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  font-size: 0px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
  font-size: 12px;
  width: 20px;
  /* height: 20px; */
  font-weight: 100;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 6px;
}
.radiocss .form-check {
  position: relative;
  display: inline-flex;
  padding-left: 1.25rem;
  margin-top: 12px;
  /* float: left; */
  margin-right: 6%;
}
.radiocss.yearofstudy .form-check {
  margin-right: 4%;
}
.registrationform .form-check {
  position: relative;
  display: inline-flex;
  padding-left: 1.25rem;
  margin-top: 12px;
  /* float: left; */
  margin-right: 5%;
}
.form-group-col {
  margin-bottom: 1.5rem !important;
}
.totalgender {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 57%;
  left: 37%;
  display: none;
  font-size: 9px;
}
.totalgenermaindiv {
  position: relative;
}
.Female {
  background: rgb(255, 2, 102);
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  height: 4px;
}
.Male {
  background: rgb(11, 204, 254);
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  height: 4px;
}
.Others {
  background: rgb(92, 123, 254);
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  height: 4px;
}
.topboxflexViewState {
  padding: 16px 1px 13px 14px;
  background: #f1f1f1;
  border-radius: 12px;
}
.topboxflexViewState .active {
  padding: 7px 6px 8px 11px;
  background: #ff283a;
  border-radius: 6px;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-right: 12px;
}
.topboxflexViewState .tabcount {
  cursor: pointer;
  margin-right: 12px;
}
