/* Opportunities Table Styling */
.opportunities-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.opportunities-table thead {
  background-color: #f4f4f4;
}

.opportunities-table th {
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  border-bottom: 2px solid #e0e0e0;
  background-color: #f9f9f9;
}

.opportunities-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
  color: #555;
  transition: background-color 0.3s ease;
}

.opportunities-table tbody tr:last-child td {
  border-bottom: none;
}

.opportunities-table tbody tr:hover {
  background-color: #f1f1f1;
}

.opportunities-table a {
  text-decoration: none;
  color: #0077cc;
  transition: color 0.3s ease;
  font-weight: bold;
}

.no-opportunities {
  text-align: center;
  color: #777;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-style: italic;
}
