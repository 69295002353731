/* Header Section */
.header-section {
  display: flex;
  flex-direction: column;
  color: #ffffff !important;
  padding-top: 50px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background: radial-gradient(
    91.5% 205.4% at 50% 49.02%,
    #222222 1.1%,
    #222222 29.18%,
    #424242 49.71%,
    #424242 76.04%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: padding-box;
  border-radius: 20px;
  height: 500px;
}

@media screen and (max-width: 768px) {
  .header-section {
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
  }
}

/* Left Section */
.header-left {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.header-logo {
  width: 140px;
  height: 140px;
  padding: 10px;
  object-fit: contain;
  margin-right: 15px;
  border-radius: 8px;
  background-color: #ffffff;
}

@media screen and (max-width: 768px) {
  .header-logo {
    width: 120px !important;
    height: 120px !important;
    margin-bottom: 10px;
    margin-right: 0px;
    padding: 0px;
  }

  .header-left {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 769px) {
  .festival-main-container .header-section .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.header-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-content h2 {
  color: #fff !important;
  font-family: "Inter";
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .header-content h2 {
    font-size: 2rem !important;
    text-align: center;
  }
}

.header-content p {
  margin: 0;
  font-size: 1rem;
  color: #fff !important;
  line-height: 1.4;
}

/* Stats Section */
.header-stats {
  margin-top: 10px;
  display: flex;
  gap: 15px;
  font-size: 0.9rem;
  background-color: #fff;
  color: black;
  width: max-content;
  padding: 5px 10px;
  border-radius: 8px;
  flex-wrap: wrap;
}

.header-stats span {
  display: block;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .header-stats {
    font-size: 0.8rem !important;
    gap: 10px !important;
    padding: 5px;
    width: 100% !important;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .big-screen-only {
    display: none !important;
  }

  .header-stats {
    gap: 20px !important;
  }

  .header-section {
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-radius: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .header-content h2 {
    font-size: 1.5rem !important;
    margin-top: 6px !important;
    margin-bottom: 8px !important;
  }

  .header-content p {
    font-size: 0.8rem !important;
    text-align: center;
  }

  .header-section {
    padding-top: 15px !important;
  }

  .header-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .header-info-divider {
    display: none;
  }
}
