#demo {
  font-size: 25px;
  color: #8444f6 !important;
  font-weight: 600;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
}

.bg-white-queiz1 {
  /* margin-top: 22% !important; */
  padding: 10px;
  background: #fff;
  height: 71vh;
  box-shadow: 0px 0px 24px 0px rgba(154, 165, 176, 0.25);
  border: none !important;
}
.nextquiz {
  background-color: #ed3836;
  color: #fff;
}
.btnshowquiz {
  background: #ccc;
  color: #000;
  border: none;
  padding: 7px 18px 7px 19px !important;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-right: 6px;
  justify-content: center;
  width: 57px;
}
.btnshowquiz.active {
  background: #5736ba;
  color: #fff;
}

.watchtime {
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
  color: #fff !important;
  box-shadow: 0px 0px 24px 0px rgba(154, 165, 176, 0.25);
  background: #fff;
  padding-top: 10px;
  border-radius: 5px;
}
.watchtimeend {
  border-bottom: 1px solid #ccc;
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
  background: #fff;
  /* background: red; */
  /* color: rgb(110, 5, 5) !important; */
  padding-top: 10px;
  border-radius: 5px;
}
.finishbtndiv {
  margin-left: auto;
  /* margin-right: auto; */
}
.finishquiz {
  background-color: #fff !important;
  color: #ff283a;
  border: 1px solid #ff283a;
  padding: 6px 18px 7px 17px;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 12px;
  margin-right: 17px;
}
.optiosn-list {
  /* margin: auto !important;
    width: 67%; */
}
.activebtn {
  background-color: #ff283a;
  color: #fff;
}
.currentindex {
  background-color: #558d36;
  color: #fff;
}
.logoquiz {
  width: 165px;
  padding: 7px;
}

.logoquiz img {
  width: 69%;
  margin-top: 7px;
}
.logoquiz1 {
  width: 130px;
}

.logoquiz1 img {
  width: 98%;
  /* margin-top: 7px; */
}
.swal2-popup.finish-alert {
  background-color: #fff !important;
  width: 600px;
}
.finish-alert .swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  text-align: left !important;
  text-transform: none;
  word-wrap: break-word;
  color: #18181b !important;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}
.finish-alert .swal2-html-container {
  color: #51636f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin: 3px 6px 3px 20px;
  line-height: 17px;
  overflow:hidden;
}
.finish-alert .swal2-icon {
  position: relative;
  /* box-sizing: content-box; */
  /* justify-content: start; */
  /* float: left; */
  width: 5em;
  /* height: 5em; */
  margin: 26px 28px -2px 30px;
  border-radius: 50%;
  border: none;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.finish-alert .swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin: 0.25em 2px 2px 15px;
  padding: 0;
}
