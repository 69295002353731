.head {
  padding: 22px 120px;
  display: flex;
  line-height: 2.5;
}

.logo {
  width: 10%;
  align-items: center;
}

.mid-head {
  width: 80%;
}

.rightside-header {
  width: 10%;
  line-height: 2.5;
}

.rightside-links {
  display: flex;
  text-align: center;
  padding-left: 0;
  margin-top: 7px;
}

.rightside-links a img {
  margin-right: 30px;
}

.search {
  width: 430px;
  height: 55px;
  border-radius: 30px;
  border: none;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #e9f0fa;
  margin-left: 290px;
}

.search-input {
  width: 400px;
  font-size: 18px;
  outline: none;
  border: none;
  padding-left: 15px;
  margin-right: 10px;
  background-color: #e9f0fa;
}

.search-icon {
  font-size: 25px;
  padding-right: 10px;
  color: #75758a;
}

/* .nav{
    width: 100%;
    margin-top: 0px;
    background: linear-gradient(
        359deg
        , rgb(24 162 228) 0%, rgb(0 173 255) 100% 150%);
} */
.nav-links {
  font-size: 15px;
  margin-right: 20px;
  color: #51636f !important;
  font-weight: 500;
}

.nav-links li {
  list-style: none;
  border-right: 1px solid #14a4e9;
}

.nav-links li:first-child {
  border-left: 1px solid #1eb0f5;
}

.nav-links li:hover {
  cursor: pointer;
  background-color: #ffb536;
}

.nav-links li a {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  color: white;
  text-decoration: none;
  padding: 17px 35px;
}

.nav-links li a svg {
  padding-right: 0;
  font-size: 20px;
  margin-right: 0px !important;
}

.menu-active.nav-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--primaryColor) !important;
  border-bottom: 2px solid #ff283a;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: -4px;
  list-style: none;
}

.nav-link {
  font-size: 15px;
  margin-right: 20px;
  color: #51636f !important;
  font-weight: 500;
}

.navbar {
  /* padding: 0.2rem 1rem !important; */
  position: fixed !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: #fff !important;
  z-index: 9999 !important;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
}

@media screen and (min-width: 787px) and (max-width: 992px) {
  .collapse.navbar-collapse.show {
    background-color: #fff !important;
    padding: 10px;

    .form-inline {
      gap: 10px;
    }
  }
}

@media screen and (max-width: 786px) {
  .collapse.navbar-collapse.show {
    width: max-content !important;

    .form-inline {
      display: none;
    }
  }
}

.header {
  border-bottom: 1px solid #e2e2e2;
}

.navbar-brand {
  margin-right: 2rem !important;
  width: 90px;
}

.logo {
  width: 100%;
}

.firstcolor {
  background-color: #7444e2;
  height: 5px;
  width: 33%;
}

.secondcolor {
  background-color: #a55bf7;
  height: 5px;
  width: 33%;
}

.thirdcolor {
  background-color: #c17cf8;
  height: 5px;
  width: 34%;
}

/* .navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0 / 73%) !important;
    font-weight: 500;
} */

.nav-item-right {
  margin-right: 15px;
  border-right: 2px solid #f5f5f5;
  padding-right: 8px;
  padding-left: 3px;
  padding-right: 15px;
}

.nav-item-right img {
  text-align: center;
  width: 17px;
  margin-top: 16px;
}

.centercls {
  text-align: center;
}

.header .progress {
  height: 7px !important;
  position: fixed;
  width: 100%;
}

.header .progress .progress-bar {
  background-color: rgba(0, 0, 0, 0) !important;
}

.menubtn {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  margin-top: 7px;
  padding: 12px 28px 12px 28px;
  border-radius: 7px;
}

.menubtn:disabled {
  background-color: #ccc !important;
}

.giflogo {
  width: 120px;
  margin-top: -2px;
  /* margin-right: 22px; */
  margin-right: 15px;
  margin-left: 8px;
}

.topsearch {
  border: 1px solid !important;
  height: 38px;
  border-radius: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
  color: #b3b3b3;
  font-weight: 400 !important;
  padding-left: 12px;
  width: 260px;
  margin-right: 20px;
}

.topimg {
  position: absolute;
  margin-left: -49px;
  font-size: 18px;
  margin-top: 11px !important;
}

.topmargin {
  margin-top: 4%;
}

.wb-rightside {
  width: 100%;
}

.iconcls {
  color: #ff6001;
  margin-left: -47px;
  cursor: pointer;
  margin-top: -5px;
  font-size: 15px;
}

.header__sortname .emaillatter {
  line-height: 38px !important;
}

.header__sortname .usernamelatter {
  line-height: 40px !important;
}

/* menu dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  padding: 0px;
  z-index: 1;
  border-radius: 6px;
}

.dropdown-content .nav-link {
  font-size: 16px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content .nav-link {
  border-bottom: 1px dotted;
  border-radius: 1px;
}

.nav-item a:before {
  content: "";
  position: absolute;
  right: -10px;
  width: 1px;
  height: 20px;
  top: 0;
  bottom: 0;
  background: #bbb;
  margin: auto;
}

.rp-content-text li {
  list-style: disc;
}

.dropdown-content .nav-link {
  background: #f1f3f4;
  margin-right: 0px !important;
  border-radius: 0;
  padding: 12px 15px !important;
}

.dropdown-content .nav-link:hover {
  background: linear-gradient(180deg, #ed3836 -12.5%, #ed3836 131.82%), #c4c4c4;
  color: #fff !important;
  margin-right: 0px !important;
  border-radius: 4px;
}

.nav-right-social a:not(:first-child) {
  margin: 0 1px;
}

.nav-right-social button {
  margin-left: 15px;
  margin-top: 0;
}

@media screen and (max-width: 480px) {
  .nav-item-right {
    margin: 0;
    padding: 0;
  }

  .w-mobile-100 {
    width: 100% !important;
  }

  .nav-right-social a:first-child {
    width: 100%;
    margin-bottom: 20px;
  }

  .acethecase-gif {
    padding-bottom: 2px !important;
  }

  .giflogo {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .nav-right-social a:first-child {
    /* width: 41%; */
  }
}

@media screen and (max-width: 1023px) {
  .menu-right li:not(:last-child) {
    border-bottom: 1px solid #efefef !important;
  }

  .acethecase-gif {
    padding-bottom: 20px;
    padding-top: 5px;
  }

  .topsearch {
    width: 100%;
  }

  .navbar.navbar-expand-lg {
    z-index: 9999999 !important;
    box-shadow: 0px 2px 15px #0003;
  }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  #navbarSupportedContent .nav-item .nav-link {
    margin-right: 2px;
  }

  .navbar-brand {
    margin-right: 1.5rem !important;
  }

  .giflogo {
    margin-right: 3px;
    margin-top: 11px;
  }

  .nav-right-social button {
    margin-left: 9px;
    font-size: 14px;
  }

  .nav-right-social a:first-child {
    width: 37%;
    text-align: right;
  }

  .topsearch {
    width: 86%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1020px) {
  .profilename {
    color: #844bf5;
  }
}

.header__sortname h3 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -2px;
  border-radius: 50%;
  margin-top: 5px;
  cursor: pointer;
  background-color: #ff6002;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}

.header__sortname img {
  width: 40px;
  height: 40px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -2px;
  border-radius: 50%;
  background-color: #ff6002;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}

.navbar-nav .nav-link {
  height: 50px;
  margin-top: 10px;
}

.home.nav-link {
  margin-top: 1px;
}

.loginpopup .modal-content,
.signuppopup .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.3rem;
  padding: 30px;
  outline: 0;
}

.loginpopup .modal-content h1,
.loginpopup .modal-content p,
.loginpopup .modal-content label,
.signuppopup .modal-content h1,
.signuppopup .modal-content p,
.signuppopup .modal-content label {
  color: #fff !important;
}

.loginpopup .modal-content p > span,
.signuppopup .modal-content p > span {
  color: red !important;
  cursor: pointer;
}

.popupclose {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  top: 13px;
  border: 1px solid #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 16px;
  position: absolute;
  text-align: center;
  cursor: pointer;
}

.gendercls {
  display: flex;
  color: #fff;
}

.gendercls input {
  height: 20px;
  width: 20px;
  margin-right: 12px !important;
  accent-color: red;
}

.gendercls input:not(:first-child) {
  margin-left: 25px;
}

.forgot_pass {
  text-decoration: underline;
  cursor: pointer;
}

.backtologin {
  text-align: center;
  color: #fff;
  margin-top: 10px;
}

.signuppopup {
  top: 5%;
}

.loginpopup input[type="text"].form-control,
.loginpopup input[type="password"].form-control,
.signuppopup input[type="text"].form-control,
.signuppopup input[type="password"].form-control,
.signuppopup input[type="tel"].form-control {
  color: #fff;
  border-radius: 8px;
}

.form-row .form-control {
  border-radius: 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.antdformbtn {
  width: 100%;
  height: 50px !important;
  border-radius: 6px !important;
  background: #fff !important;
  color: #000 !important;
}

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
  font-size: 16px;
}

input[type="password"].form-control {
  background: rgba(255, 255, 255, 0) !important;
  height: 48px;
  border: none !important;
  font-size: 16px !important;
  border: 1px solid #dbdbdb !important;
  color: #333333;
  font-weight: 400 !important;
  padding: 10px;
}

.signupbtn {
  width: 100% !important;
  background: #fff !important;
  /* padding: 12px 10px 12px 10px; */
  margin-top: 17px !important;
  color: #000 !important;
  height: 50px !important;
}

.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
  background: #0000;
  color: #fff;
}

.ant-input-password-icon svg {
  font-size: 16px !important;
  fill: #fff !important;
}

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  background: #000;
  justify-self: center;
  color: #fff;
  border-radius: 12px;
}

.registrationmodalcss {
  color: #fff;
  font-size: 18px;
}

.createorg.modal.show .modal-dialog {
  transform: none;
  top: 20%;
  z-index: 999999999;
  border: 1px solid #737373;
}

.form-control.select-code {
  width: 35%;
  color: white;
  cursor: pointer;
}

.phone-number-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

input[type="text"].form-control.phone-number {
  width: 60%;
}

.form-control.select-code option {
  background-color: #000;
  color: #fff;
}

.loginpopup .modal-content .reset-error-msg {
  color: #ff283a !important;
  text-align: center;
}

.loginpopup .modal-content .reset-success-msg {
  color: green !important;
  text-align: center;
}

.loginpopup .modal-content .login-link {
  color: green !important;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.loginpopup .modal-content .reset-password-match-error {
  color: #ff283a !important;
  text-align: start;
  margin-top: 4px !important;
}
