.imgsize {
  width: 100%;
  border-radius: 10px;
  margin-top: 20%;
}
.event_show:hover {
  box-shadow: 0px 2px 4px #0000005e;
}
.event_show {
  margin-bottom: 10px;
  /* box-shadow: 0px 2px 4px #0000005e; */
  padding: 14px 10px 13px 1px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
.flex-column.nav.nav-pills .active {
  background-color: #6747c5 !important;
}
.flex-column.nav.nav-pills .active a {
  color: #fff !important;
}
/* .nav-pills .nav-link {
    border-radius: .25rem;
    background: #5736ba;
    color: #fff !important;
} */
.btnshow {
  background: #5736ba;
  color: #fff;
  border: none;
  padding: 8px 21px 8px 22px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.left-sidebar ul li {
  font-size: 16px;
  transition: 0.5s;
  border-bottom: 1px solid #6747c5;
  background: #5736ba !important;
  color: #fff !important;
}
