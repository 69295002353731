@import "../../../utils/Colors/index.css";

.main {
  margin-top: 4%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.upper-slide .slick-slider {
  text-align: center;
  margin-bottom: 3%;
}

.main-stats {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  text-align: center;
}

.bannerimg {
  width: 90%;
}

.cstm-title {
  font-size: 26px;
}

.cstm-title span {
  font-weight: 700;
  background: linear-gradient(
    179.54deg,
    #5734ba -2.74%,
    #882ad4 51.19%,
    #a142ec 107.38%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #00000024;
}

.nav-item-right span {
  color: #313131 !important;
  text-transform: capitalize;
}

p {
  color: #423f3f !important;
}

.nav-item {
  /* border-right: 1px solid #834bf5; */
}

/* logo upload */

.uploader input[type="file"] {
  display: none;
}

.uploader label {
  float: left;
  clear: both;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.uploader #file-image.hidden {
  display: none;
}

.uploader #file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 100%;
}

.uploader #start {
  float: left;
  clear: both;
  width: 100%;
}

.uploader div {
  margin: 0 0 0.5rem 0;
  color: #5f6982;
}

.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}

.uploader div {
  margin: 0 0 0.5rem 0;
  color: #5f6982;
}

.uploader #notimage.hidden {
  display: none;
}

.uploader #notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}

.uploader .btn {
  display: inline-block;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  clear: both;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background: #454cad;
  border-color: #454cad;
  cursor: pointer;
}

.bannerview {
  width: 100%;
}

.img-wrap {
  position: relative;
  display: inline-block;
  border: 1px red solid;
  font-size: 0;
}

.img-wrap .close {
  position: absolute;
  top: -7px;
  right: -6px;
  z-index: 100;
  background-color: #fff;
  padding: 9px 8px 9px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  font-size: 31px;
  line-height: 10px;
  border-radius: 50%;
}

.img-wrap:hover .close {
  opacity: 1;
}

.registrationmainrow {
  display: inherit;
  /* width: 72%; */
  text-align: left;
  background: #fff;
  margin-top: 15px;
  /* border-bottom: 2px solid; */
  /* padding: 17px; */
}

.registrationmainrow p {
  color: #814bf5 !important;
}

.registerbtn button {
  text-align: center;
  margin: 0;
  background: #894cf6;
  margin-top: 20px;
  padding: 14px 26px;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
  border: none;
}

.registerbtn {
  width: 100%;
  text-align: center;
  border-top: 1px dashed #dbdbdb;
  margin-top: 15px;
  padding-top: 10px;
}

.successmsg {
  color: green;
  text-align: center;
}

.form-text.checkerror {
  font-size: 14px;

  color: #ff6262 !important;
}

.form-text.checkerror {
  margin-top: 10px;
}

.galleryimg {
  width: 100%;
  padding: 15px;
}

.bannerimg {
  width: 90%;
}

.importantfield {
  color: red;
}

.tc {
  text-align: center;
  margin-top: 10px;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.margingtop {
  margin-top: 6%;
}

.navbar-nav li .btn .menubtn {
  cursor: none;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    -webkit-flex-direction: row;
    flex-direction: row;
    margin-right: -10px;
  }

  /* .nav-link {
    font-size: 15px;
} */
}

.career-content li p a svg {
  margin-right: 12px;
}

.career-content li p svg {
  margin-right: 12px;
}

.upper-slide {
  margin-top: 68px;
}

.counter-boxes .col-md-2 {
  margin-bottom: 25px;
}

.top-ftr input {
  padding-right: 30px;
}

@media screen and (max-width: 480px) {
  .opportunities .ant-tabs-tab.ant-tabs-tab-active span,
  .opportunities .ant-tabs-tab span {
    font-size: 14px !important;
  }

  .opportunities .ant-tabs-tab {
    padding: 0px 20px 0px 20px !important;
  }

  .newsletter-form input {
    width: 100% !important;
    padding-right: 30px;
    margin-bottom: 10px !important;
  }

  /* by hemant */
  .counter-boxes .col-md-2 {
    margin-bottom: 25px;
    width: 128px;
  }

  .boxcss img {
    margin-bottom: 0px !important;
    border: 1px solid #fce9ea;
    border-radius: 50%;
    background: #fff;
    width: 40px !important;
    height: 40px !important;
    margin-top: 2px !important;
  }

  .boxcss .card-title {
    color: #222;
    font-size: 11px !important;
  }

  .boxcss .card-subtitle {
    font-size: 13px !important;
  }

  .boxcss {
    height: 120px;
    padding: 13px 2px 2px 2px !important;
  }

  .cstm-boxes {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .formobilecardcls {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .cstm-title {
    font-size: 26px;
    margin-top: 45px;
  }

  .days-left {
    right: 15px !important;
  }

  .days-left::after {
    display: none;
  }

  .days-left {
    padding: 5px 13px 3px 15px !important;
  }

  .carousel-dots button {
    width: 3px !important;
    height: 8px !important;
    padding: 4px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .counter-boxes .col-md-2 {
    width: 50%;
  }

  .newsletter-form input {
    padding-right: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .counter-boxes .col-md-2 {
    flex: 0 0 32.9% !important;
    max-width: 39.666667% !important;
  }

  .opportunities-card .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .subscriberheading {
    line-height: 28px !important;
  }

  .top-ftr .col-md-7,
  .top-ftr .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .top-ftr input {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .opportunities-card .col-md-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

.upper-slide .slick-prev,
.upper-slide .slick-next {
  z-index: 9999 !important;
  /* display: none !important */
}

.slick-dots li.slick-active button:before {
  color: #814bf5 !important;
}

.slick-dots li button:before {
  font-size: 15px !important;
}

.upper-slide .slick-prev {
  left: -1px !important;
  position: absolute;
  /* display: none !important; */

  top: 12px;
  bottom: 12px;
  margin: auto;
  color: #fff;
  width: 45px;
  background: rgba(0, 0, 0, 0.5) !important;
  font-size: 32px;
  display: none !important;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.upper-slide .slick-next {
  right: 1px !important;
  position: absolute;
  top: 12px;
  bottom: 12px;
  margin: auto;
  color: #fff;
  width: 45px;
  background: rgba(0, 0, 0, 0.5) !important;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: none !important;
}

.slick-prev,
.slick-next {
  font-size: 0;
  /* line-height: 0; */
  position: absolute;
  top: 100% !important;
  display: none;
  width: 50px !important;
  height: 100% !important;
  /* padding: 0; */
  /* transform: translate(0, -50%); */
  /* cursor: pointer; */
  /* color: transparent; */
  border: none;
  /* outline: none; */
  background: transparent;
}

.slick-slide img {
  display: block;
  /* padding-right: 36px;
    padding-left: 33px; */
}

.italic {
  font-style: italic;
}

.profilename {
  color: #854bf6 !important;
}

.main #testimonials {
  background-color: #fff;
  padding-bottom: 0px !important;
}

.main .upper-slide .opportunity-detail.opportinity-banner {
  /* margin-top: 0px; */
  margin: auto;
  text-align: center;
}
.cardSection {
  background-color: #fff;
}
.cardSection h1 {
  font-weight: var(--secondFontWeight);
}
.cardSection h1 span {
  font-style: var(--fontStyleitalic);
  color: #ff0266;
}
.opportunitySection {
  background: #eff3f7;
}
.opportunitySection h1 span {
  font-style: var(--fontStyleitalic);
  color: #ff0266;
  font-weight: 700;
}
.workshopSection h1 span {
  font-style: var(--fontStyleitalic);
  color: #0bccfe;
}
.subscribersection h1 span {
  font-style: var(--fontStyleitalic);
  color: #ff0266;
}
.companiesboxes {
  padding: 20px;
  box-shadow: 7px 5px 4px #76737321;
  border-radius: 15px !important;
  margin-bottom: 35px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 125px;
}
.companiesboxes div img {
  width: 100%;
  height: 80px;
  object-fit: contain;
  padding: 15px;
}

.subscribersec {
  background: #6b2770;
  border: 8px solid #191f23;
  border-radius: 24px;
  height: 300px;
  display: flex;
}
.subscribersec .sub-leftside {
  width: 660px;
  margin-top: 35px;
  margin-left: 21px;
}
.subscribersec .sub-leftside h1 {
  color: #fff;
  font-weight: 600;
}
.bg-color-white {
  background-color: #fff !important;
}
.main .upper-slide .ant-tabs-top .ant-tabs-nav {
  position: absolute;
  padding-left: 75px;
  padding-top: 25px;
  z-index: 1;
}
.main .upper-slide .ant-tabs-top .ant-tabs-content-holder {
  padding: 0px;
}
.main .upper-slide .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-size: 18px;
  border: 2px solid #ff283a;
  padding: 4px 16px 4px 16px;
  background: #ff283a;
  border-radius: 8px;
}
.main .upper-slide .ant-tabs-tab .ant-tabs-tab-btn {
  color: #ff283a !important;
  font-size: 18px;
  border: 2px solid #ff283a;
  padding: 4px 16px 4px 16px;
  /* background: #FF283A; */
  border-radius: 8px;
}
.main .upper-slide .ant-tabs-top .ant-tabs-nav::before {
  border-bottom: 1px solid #f0f0f000;
}
.sub-rightside img {
  width: 100%;
}
.opportunitySection .ant-tabs {
  color: rgb(17 17 17);
}
.opportunitySection svg,
.testimonialSection svg,
.workshopSection svg {
  font-size: 14px;
}
.mainhomepage .footer {
  display: block;
}
.opportunitySection .text-center {
  font-weight: 700;
}
.sub-leftside input {
  width: 92%;
  background: #6b2770;
  border: none;
  border-bottom: 1px solid #e9c0e9;
  font-size: 20px;
}
.gly-rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(310deg);
  -moz-transform: rotate(310deg);
  -ms-transform: rotate(310deg);
  -o-transform: rotate(310deg);
  transform: rotate(310deg);
  display: inline-block;
  font-size: 20px !important;
  color: #fff;
  margin-left: -16px;
}
.testimonial-content-box p {
  margin-right: 28px;
  text-align: justify;
}
.text-center {
  text-align: center !important;
  font-weight: 700;
}
.sub-leftside input,
.sub-leftside input::placeholder {
  color: #fff;
}

.testimonialSection #testimonials .subtitle {
  color: #51636f !important;
  font-weight: 200;
}
.testimonialSection h1 {
  color: #ff0266;
  font-weight: 700;
}
.opportunitySection .swiper-btn-next,
.opportunitySection .swiper-btn-prev {
  left: 0%;
}
.opportunitySection .workshops .swiper-pagination {
  left: 0.5% !important;
}
.opportunitySection .ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  /* width: calc(((102% - 1100px) / 2) + 1240px) !important; */
  /* margin-left: 130px; */
  min-height: 0;
  /* border: 1px solid #efefef; */
  /* background-color: #f8f9fa; */
  /* border-radius: 10px; */
  padding: 15px;
  border-top-left-radius: 0px;
}
.homepageseconbanner {
  width: 90% !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 12px !important;
}

.homepagemarque .img-fluid {
  max-width: 100%;
  padding: auto;
  height: 100px;
  margin: auto;
  text-align: center;
}
.homepagemarque > div > div {
  position: relative;
  margin: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background: #ffffff;
  box-shadow: 6px 7px 22px rgba(33, 38, 106, 0.15);
  border-radius: 10px;
  text-align: center;
  top: 0;
}
.homepage-banner .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  /* margin-left: -78.5%; */
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.homepage-banner.singlebanner .swiper-wrapper {
  margin-left: 0px;
}
.homepage-banner .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  background: #fefefe;
  border-radius: 50%;
  /* font-size: 12px; */
  width: 40px;
  height: 40px;
}
.homepage-banner .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  background: #fefefe;
  border-radius: 50%;
  /* font-size: 12px; */
  width: 40px;
  height: 40px;
}

.homepage-banner .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 19px;
  color: #ed3836;
}
.homepage-banner .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 19px;
  color: #ed3836;
}
.bannerslideimg {
  border-radius: 15px;
}
@media screen and (min-width: 320px) and (max-width: 786px) {
  .homepage-banner .swiper-wrapper {
    margin-left: 1px;
    margin-top: 18px;
  }
  .ant-tabs {
    box-sizing: border-box;
    width: 100%;
    /* text-align: left; */
    padding: 0;
    color: rgb(132 75 245);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
  }
  .opportunitySection .opportunities {
    padding-bottom: 50px;
  }

  /* mobile navigation */

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    background: #fff;
    align-items: center;
  }
  .nav-right-social a:first-child {
    width: 7px !important;
  }
  .form-inline {
    justify-content: space-around;
  }
  .header__sortname img {
    margin-top: 19px;
  }
  .main .upper-slide .ant-tabs-top .ant-tabs-nav {
    position: absolute;
    padding-left: 114px;
    padding-top: 168px;
    font-size: 12px;
    z-index: 999;
  }
  .swiper-bn-next {
    margin-top: -8px;
    margin-right: -43%;
  }
  .main .upper-slide .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    font-size: 14px;
    border: 2px solid #ff283a;
    padding: 4px 10px 4px 10px;
    background: #ff283a;
    border-radius: 8px;
  }
  .main .upper-slide .ant-tabs-tab .ant-tabs-tab-btn {
    color: #ff283a !important;
    font-size: 14px;
    border: 2px solid #ff283a;
    padding: 4px 10px 4px 10px;
    /* background: #FF283A; */
    border-radius: 8px;
  }
  /* .boxcss {
  padding: 20px;
  box-shadow: 50px #E3E3E3;
  box-shadow: 0px 2px 4px #00000024;
  height: 150px !important;
  border-radius: 15px;
  margin-bottom: 35px;
  background: #fff;
  width: 150px;
  border: 1px solid #000;
} */
  .boxcss {
    padding: 20px;
    box-shadow: 50px #e3e3e3;
    box-shadow: 0px 2px 4px #00000024;
    height: 120px !important;
    border-radius: 15px;
    margin-bottom: 35px;
    background: #fff;
    border: 1px solid #000;
  }
  .desktop-card {
    /* display: none !important; */
  }
  .opportunitySection .ant-tabs-content-holder {
    flex: auto;
    min-width: 0;
    width: 100% !important;
    margin-left: 0px !important;
    min-height: 0;
    /* border: 1px solid #efefef; */
    /* background-color: #f8f9fa; */
    /* border-radius: 10px; */
    padding: 15px;
    border-top-left-radius: 0px;
  }
  .footer {
    background: #111111 !important;
  }
  .subscribersec .sub-leftside h1 {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
  }
  .sub-rightside {
    display: none;
  }
  .subscribersec {
    height: 200px;
  }
  .swiper-pagination .swiper-pagination-bullet {
    width: 20px !important;
    border-radius: 0;
    height: 2px;
  }

  /* spacing issue  */
  .cardSection .pt-5,
  .py-5,
  .opportunitySection .pt-5,
  .main #testimonials {
    padding-top: 1rem !important;
  }
  .opportunities-card .card-body {
    padding: 0px 0px 0px 22px;
  }
  .show-opportunity {
    margin-top: -20px;
  }
  .opportunities-card {
    margin-bottom: 0px;
    justify-content: center;
  }
  /* testimonial */
  .testimonialSection {
    margin-top: -84px;
  }
  /* workshop */
  .workshopSection {
    margin-top: -28%;
  }
  .workshopSection div h1 {
    padding-bottom: 0px !important;
    margin-top: 15px;
  }
  .cardSection h1 {
    margin-bottom: 0px !important;
    margin-top: 15px;
  }
  /* home page nbanner */
  .homepage-banner.singlebanner .swiper-wrapper {
    margin-left: 0px;
    margin-top: 20px;
  }
  .homepage-banner .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 62% !important;
  }
  .swiper-button-prev {
    top: 62% !important;
  }
  /* .navbar
{
  padding: 0px !important
} */
  .navbar .container-fluid {
    /* padding-right: 5px !important; */
    /* padding-left: 5px !important; */
  }
  .header__sortname .usernamelatter {
    margin-top: 25px !important;
  }
}
.tooltipcls {
  width: 500px !important;
  line-height: 20px !important;
}
/* input[type="date"]::-ms-clear {
  display: none;
} */
input[type="date"]::-moz-clear {
  display: none !important;
}
button.ui-datepicker-current {
  display: none;
}
input[type="date"]::-ms-clear {
  display: none !important;
}

input[type="date"]::webkit-clear-button {
  display: none !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 47px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 44px !important;
}
.file-upload {
  text-transform: lowercase;
  color: green !important;
}
.file-upload .fa.fa-file {
  font-size: 20px !important;
}
.success-save {
  color: green !important;
  text-align: center;
  font-size: 16px;
}
.errorshow {
  text-align: center;
  font-size: 14px;
  color: red;
}
.banner_class {
  width: 100%;
  margin-bottom: 12px;
}
.banner-title {
  text-align: left;
}
.editsavebtn svg {
  font-size: 16px;
  margin-bottom: 3px;
}
.finallogoerror {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}
.multiplebanner {
  margin-bottom: 0px;
}
.bannerpreviewimg {
  width: 100%;
}
.removebanner {
  border: none;
}
.planremove {
  margin-top: 7px;
}
.certificateTag {
  margin-left: 30px;
  margin-top: 7px;
  position: absolute;
}
.roundstatus .fa.fa-circle {
  margin-right: 7px;
}
.signuppopup .form-group-col {
  margin-bottom: -0.5rem !important;
}
.rememberme {
  margin-left: 20px;
  margin-top: 5px;
  position: absolute;
}
.remindmeandforgotpass input[type="checkbox"] {
  margin-left: 12px;
}
.homepage-banner .swiper .swiper-pagination {
  bottom: -10px !important;
  /* width: auto !important; */
  width: 100% !important;
  display: inline-block;
  position: sticky;
  z-index: 999999;
  margin-top: 17px;
  text-align: center;
}
.registrationform .quiz_tab span {
  width: 35px;
  display: inline-block;
  height: 35px;
  background: #dcddde;
  margin-left: 0;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  color: #18181b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  margin-right: 12px;
  font-weight: 700;
}
.registrationform .quiz_tab.activebtn span {
  width: 35px;
  display: inline-block;
  height: 35px;
  background: #fff;
  margin-left: 0px !important;
  color: #ff283a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  margin-right: 11px;
}
.registrationform .quiz_tab {
  width: 160px;
  justify-content: left;
  font-size: 13px;
  padding: 10px;
}
.footershow {
  display: block !important;
}
