/* Profile CSS */

.profile__detail {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .profile__detail {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .profile-btns {
    padding: 0px;
    width: 100%;
    justify-content: space-between;

    div {
      padding-left: 0px;
    }

    button {
      font-size: 12px;
      padding: 5px 10px;
    }
  }

  .profile__detail__section .nav-tabs {
    display: flex;
    flex-shrink: 1;
    max-width: 100%;
    gap: 4px;
    flex-wrap: nowrap;
    justify-content: left;
    text-align: center;
    background-color: transparent;
    border: none;
    margin-top: 20px;
    padding: 0px;

    .nav-link {
      margin-right: 0px !important;
      font-size: 12px;
      width: 25% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      text-align: center !important;
    }
  }
}

.profile_detail_name_wrapper,
.profile-btns {
  display: flex;
  align-items: center;
}

.profile__detail__sortname h3 {
  width: 90px;
  height: 90px;
  line-height: 89px;
  /* text-align: center; */
  padding: 0px 0px 20px 21px;
  letter-spacing: -4px;
  border-radius: 50%;
  background-color: #ff6002;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  text-transform: capitalize;
}
.profile__detail__sortname img {
  width: 90px;
  height: 90px;
  line-height: 89px;
  letter-spacing: -4px;
  border-radius: 50%;
  border: 1px #ff6002 solid;
}

.profile__detail__name p {
  font-size: 15px;
  color: #a3acb3;
}

.profile__detail__section {
  background: #fff;
  box-shadow: 0px 1px 25px #00000024;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
}

.profile__detail__name {
  width: 38%;
  margin-left: 2.5rem;
}

.profile__detail__name h3 {
  font-size: 22px;
  font-weight: 700;
  color: #2b2b2e;
  text-transform: capitalize;
}

.pink__btn {
  border: 2px solid #ff283a;
  color: #ff283a;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
}

.pink__btn:hover {
  background-color: #ff283a;
  color: #fff;
}

.edit__profile {
  display: flex;
  gap: 20px;
  padding-left: 2rem;
  align-items: center;
}

.progress__bar {
  display: flow-root;
}

.progress__bar p {
  float: left;
  color: #979798;
  padding-right: 25px;
  color: #898989;
  font-weight: 500;
}

.progress__bar .progress {
  height: 5px;
  position: relative;
  top: 9px;
}

.cstm__hrcolor {
  border: 1px solid #979797;
}

.cstm__tab {
  border: none;
}

.cstm__tab .nav-link {
  border: none;
  padding-left: 0;
  padding-right: 0px;
  color: #898989;
  font-weight: 500;
}

.profile__detail__section .cstm__tab .nav-link.active {
  border-bottom: 2px solid #ff283a;
  color: #ff283a !important;
  font-weight: 500;
}
.profile__detail__section .nav-tabs .nav-link {
  height: auto;
  width: auto;
  position: relative;
  background-color: #fff;
  color: #51636f;
  border-radius: 0px;
  margin-right: 45px;
}
.profile__detail__section .nav-tabs {
  display: flex;
  justify-content: left;
  text-align: center;
  background-color: transparent;
  border: none;
  margin-top: 20px;
  padding: 0px;
}
.cstm__tab li {
  margin-right: 40px;
}
.settingcss span {
  /* position: absolute; */
  width: 57px;
  height: 20px;
  left: 653px;
  top: 504px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #18181b;
}
.settingcss {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  color: #51636f !important;
}
.userdetail {
  margin-top: 8%;
  padding-left: 0;
}
.noneborder .form-control {
  border: none !important;
}
/* .collage_info .form-check
{
    margin-right: 8%;
    margin-left: -10px;
} */
.collage_info .form-row .form-group-col .checktitle .input-titlle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #191f23 !important;
}
.collage_info .form-row .form-group-col {
  margin-bottom: 1.5rem !important;
}
.collage_info .form-row .form-group-col .input-titlle {
  font-family: "Inter";
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #191f23 !important;
}

.collage_info
  .form-row
  .form-group-col
  .checktitle
  .form-check
  .form-check-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  margin-top: 2px;
  color: #191f23;
}
.collage_info button {
  background: #ff283a !important;
  border-radius: 8px;
  color: #fff !important;
  padding: 10px;
  float: right;
  border: none;
  width: 140px !important;
}
.sociallinks button {
  background: #ff283a;
  border-radius: 8px;
  padding: 10px;
  float: right;
  border: none;
}
.sociallinks button:hover {
  background: #ff283a;
  border-radius: 8px;
  padding: 10px;
  float: right;
  border: none;
}
.collage_info button:hover {
  background: #ff283a;
  border-radius: 8px;
  padding: 10px;
  float: right;
  border: none;
}
.uploader.profileupdate label {
  float: left;
  clear: both;
  width: 100%;
  padding: 11px 22px 0px 22px;
  text-align: center;
  background: #ff283a;
  border-radius: 7px;
  border: none;
  margin-top: 17%;
  cursor: pointer;
}
.uploader.profileupdate p {
  color: #fff !important;
}

.profileimgpopup {
  position: absolute;
  right: 10px;
  top: 0px;
  border-bottom: 0;
}
.profile__detail__sortname img {
  cursor: pointer;
}

.flexwithcontent {
  justify-content: space-between;
  display: flex;
}
.deleteicon {
  color: #51636f;
  font-size: 24px;
  left: 79%;
  margin-top: -2px;
  position: absolute;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #ff283a;
  color: #fff;
  font-size: 1em;
}
.flexwithcontent h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #51636f;
}
.css-1nmdiq5-menu {
  color: #000 !important;
}
.css-13cymwt-control {
  min-height: 50px;
}
.passwordChange button {
  background: #ff283a;
  border-radius: 8px;
  padding: 13px 25px 32px 25px;
  float: right;
  border: none;
}
.cursorPointer {
  cursor: pointer;
}
span.ant-radio + * {
  padding-right: 40px;
  padding-left: 8px;
  font-size: 15px;
  font-weight: 400;
}
.ant-radio-inner::after {
  background: #ff283a;
}
.ant-radio-checked::after {
  border: 1px solid #ed3836;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #ed3836;
}
.deletstatusmodal {
  color: #000;
}
.deletstatusmodal .modal-content {
  padding: 28px 28px 25px 28px;
  border-radius: 22px;
}
.deletstatusmodal .modal-dialog {
  max-width: 700px !important;
}
.deletinstuction li {
  list-style: disc;
}
.collage_info .removemodal {
  margin-left: 20px;
  width: 250px !important;
  padding: -1px;
  height: 50px;
  margin-top: 17px;
  background: #c8c8c8 !important;
  color: #000 !important;
}
.deletstatusmodal h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  color: #18181b;
}
.deletstatusmodal .customhr {
  background: #000;
  width: 6%;
  display: inline-block;
  height: 0.5px;
}
.deletstatusmodal {
  margin-left: -16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 125% */
  color: #18181b;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: #fff;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}
.registration-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.maintitle {
  color: #fff;
  font-size: 30px;
}
.infoicon {
  margin-left: 8px;
  color: red;
}

@media screen and (max-width: 767px) {
  .flexwithcontent {
    margin-bottom: 20px;

    h3 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    .cursorPointer {
      width: 10%;
      margin-top: 5px;

      .deleteicon {
        margin-top: 0px;
        left: 88%;
      }

      .delete-profile-text {
        display: none;
      }
    }
  }

  .profile__detail {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
    justify-content: center;

    .edit__profile {
      padding-left: 0px;
    }
  }

  .deletstatusmodal .modal-content {
    border-radius: 0px !important;
    margin: 0px !important;

    button {
      font-size: 12px;
    }
  }
}
