.incomplete {
  background-color: #ff283a33;
  color: #ff283a !important;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  width: 115px;
}
.complete {
  background-color: #21965333;
  color: #219653 !important;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  width: 100px;
}
.roundbtn {
  background-color: #fff;
  color: #219653 !important;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  margin: auto;
  text-align: center;
  border: 2px solid #219653;
  width: 100px;
}
.roundDot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #219653;
  margin-right: 8px;
}
.registerationheader {
  background-color: #fff;
  border-top-left-radius: 8px;
}
.navitem_icon .fa.fa-caret-down {
  margin-top: 5px;
}
.ant-table-thead .ant-table-cell:nth-child(2) {
  text-align: center;
}
.registerSuccess {
  color: #219653;
  padding: 4px 8px;
  border-radius: 8px;
  background: rgba(33, 150, 83, 0.2);
  margin-right: 12px;
  font-size: 12px;

  text-transform: uppercase;
}
.registerPending {
  color: #ff283a;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Inter;
  padding: 4px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
  border-radius: 8px;
  background: rgba(255, 40, 58, 0.2);
}
.ant-table-cell {
  /* text-transform: uppercase; */
}
