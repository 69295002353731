.work-head {
  background-color: #fff;
  /* background: linear-gradient( to bottom, #ed3836 0%, #ef5f36ba 50%, #ffff 50%, #ffffff 100%); */
  padding-top: 70px;
  /* height: 200px; */
  /* margin-top: 62px; */
}

.workshop-btn {
  display: flex;
  width: 200px;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  height: 60px;
  background-color: #ffff;
  margin-left: auto;
  margin-right: auto;
  -moz-box-shadow: 0px 0px 10px 0px #ccc;
  -webkit-box-shadow: 0px 0px 10px 0px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
}
.workshop-btn h3 {
  font-size: 20px;
  padding: 1px;
  margin-top: 10px;
  text-transform: uppercase;
}
.workshop-text {
  font-size: 20px;
  font-weight: 300;
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  color: #989898 !important;
  text-align: center;
}

.workshop-body {
  /* display: flex; */
  justify-content: space-between;
  padding-bottom: 60px;
}

@media screen and (max-width: 480px) {
  .wb-leftside {
    position: fixed;
    z-index: 9999999;
    top: 62px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: scroll;
    left: -450px;
    transition: 0.7s;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .workshop-body .opportunities-rightside .col-md-4 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.tabchangbtn {
  background-color: #ffff;
  margin-right: auto;
}

.tabchangbtn.active {
  background-color: #ed3836;
  margin-right: auto;
  border-radius: 60px;
  color: #fff;
}
.container-fluids {
  padding: 0px 120px 0px 120px;
}
.opportunities-rightside-search {
  width: 100%;
  height: 55px;
  border: 1px solid #cccccc87;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  padding: 13px 25px 20px 115px;
}
.opportunities-rightside-search .search-input-workshop {
  background-color: #fff !important;
  padding-left: 35px;
  margin-top: 12px;
}
.rightcard-final {
  border: 1px solid;
  padding: 9px;
  border-radius: 60px;
  width: 240px;
  margin-top: 10px;
}
.totalEvent {
  font-size: 28px;
  font-weight: 700;
  margin-top: 14px;
}
.wb-rightside p {
  margin-top: 4px;
  margin-bottom: 0;
  color: #000 !important;
}
.descriptionshow {
  margin-top: 19px;
}
/* .footer
{
    display: block !important;
} */
