.ant-upload-list-picture .anticon svg {
  height: 2rem;
  width: 2rem;
  fill: #000 !important;
  display: block !important;
}

.ant-collapse-content-box .anticon svg {
  fill: #000 !important;
  display: block !important;
}

.ant-collapse-extra svg {
  height: 1.25rem;
  width: 1.25rem;
}

.ant-upload-list-item-thumbnail.ant-upload-list-item-file {
  display: flex;
  align-items: center;
}

.popup-content ol {
  padding-left: 20px;
}

.popup-content ol li {
  margin-bottom: 10px;
  list-style: square;
}

.popup-content ul {
  padding-left: 20px;
}

.popup-content ul li {
  margin-bottom: 10px;
  list-style: square;
}

.show-case-study {
  .ant-row.ant-form-item-row {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  .ant-col.ant-form-item-control {
    width: max-content;
    margin-left: 10px;
  }
  .ant-col.ant-form-item-label {
    padding: 0px;
  }
}

.create-submission-form-label {
  font-size: 16px !important;
}

.form-body.create-submission-form {
  padding: 15px !important;
  margin-bottom: 15px;

  .input-field {
    margin-bottom: 20px;
  }
}

.create-submission-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .add-field-button {
    padding: 5px 10px;
    font-size: 14px;
  }
}

.no-custom-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 15px;
  
  p {
    color: #b5b5b5 !important;
    font-size: 16px;
  }
}