.login-body {
  padding: 45px 28px;
  text-align: center;
  border-radius: 22px;
}
.login-body h1 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}
.login-body h1 Span {
  color: #a357e0;
}
.login-body input {
  background-color: #fff !important;
}
.login-body button {
  background-color: #fff;
  color: #a357e0;
  border: none;
  font-size: 20px;
  border: 1px solid #a357e0;
  font-weight: 600;
  padding: 10px 138px;
  border-radius: 20px;
}

.login-body p {
  margin: 20px 0px;
  font-size: 18px;
  font-weight: 600;
}
.login-body p:first-child {
  text-align: right;
  margin: 10px 0px;
}
.forget-password {
  text-align: right;
}
.bg {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  top: 50px;
  bottom: 50px;
  margin-top: 7%;
}
.signup-bg {
  /* background: linear-gradient(
        180deg
         , #7942f3 -12.5%, #af4bff 131.82%), #C4C4C4;
         top: 50px;
         bottom: 50px; */
  /* margin-top: 7%; */
}
.register-body-form {
  height: 50px;
  border-radius: 8px;
  padding: 0 15px 0 50px;
  margin-bottom: 0;
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid #9e9e9e !important;
  color: #828a90;
  font-size: 14px;
  font-weight: 600;
}
.material-icons {
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  position: absolute;
  color: #9e9e9e;
  left: 70px;
  /* top: 0; */
  /* bottom: 0; */
  margin-top: -37px;
  height: 23px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.main-div {
  padding: 2%;
  border-radius: 23px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #079ae0;
  border-color: #079ae0;
}
.sign-up {
  color: red;
  /* border: 1px solid; */
  padding: 6px 10px 6px 7px;
  border-radius: 5px;
  margin-right: 15px;
  font-size: 15px;
  text-decoration: revert;
}
.forgtbtn {
  /* width: 180px !important; */
  padding: 12px !important;
  font-size: 15px !important;
  border-radius: 8px !important ;
  float: left !important;
  margin-bottom: 10px;
}
.loginbtn {
  width: 100%;
  background: #fff;
  padding: 12px 10px 12px 10px;
  margin-top: 25px;
}
.resetBtn {
  width: 100%;
  background: #fff;
  padding: 12px 10px 12px 10px;
  margin-top: 25px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
}
.resetBtn:active {
  background-color: #3779eb;
  color: white;
  transition:background-color 0.3s;
}
.resetBtn:disabled {
  cursor: not-allowed;
  background: #acaaaa;
  color: #000;
}
.loginbtnlogin {
  float: right;
  margin-top: 10px;
}
.errormsg {
  color: #ff283a !important;
}
.fp {
  text-align: right;
  width: 100%;
  color: red;
  float: right;
  padding: 18px 0px 12px 0px;
}
.fl {
  float: left;
}
.errormsgdd {
  padding: 10px;
  color: green !important;
}
.successfullyreg {
  color: green !important;
  font-size: 14px;
  text-align: center;
}
.customhr {
  background: #616060;
  width: 10%;
  display: inline-block;
}
.remindmeandforgotpass {
  display: flex;
  margin-top: 13px;
  justify-content: space-between;
  color: #fff;
}
.signuppopup.modal.show .modal-dialog {
  max-width: 900px !important;
  top: 7%;
}

.loginpage {
  color: #000;
}
.loginpage.resetpass-main-div .login-body input {
  background-color: #000 !important;
  margin-left: 12px;
}
.resetpass-main-div .login-body button {
  width: 100%;
}