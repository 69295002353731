.react-quiz-container {
  margin: 20px;
  /* width: 500px; */
  display: flow-root;
  font-family: "Roboto", sans-serif;
  /* border: 1px solid #d1dfe3; */
  /* padding: 20px; */
  background: #fff;
  border-radius: 8px;
  display: flex;
  /* box-shadow: -6px 6px #a5cfda; */
}
.react-quiz-container p {
  line-height: 26px;
}
.react-quiz-container1 {
  margin: 20px;
  /* width: 500px; */
  display: flow-root;
  font-family: "Roboto", sans-serif;
  border: 1px solid #d1dfe3;
  padding: 20px;
  background: #fff;
  box-shadow: -6px 6px #a5cfda;
}
.react-quiz-container-finish {
  margin: 20px;
  /* width: 500px; */
  text-align: center;
  display: flow-root;
  font-family: "Roboto", sans-serif;
  border: 1px solid #d1dfe3;
  padding: 20px;
  background: #fff;
  box-shadow: -6px 6px #a5cfda;
}
.quiz-finish-banner-section1 .container {
  padding-top: 10%;
}
.react-quiz-container h5 {
  font-family: "Roboto", sans-serif;
  /* text-align: justify; */
}
.react-quiz-container li {
  font-family: "Roboto", sans-serif;
  text-align: justify;
  font-size: 16px;
  /* color: #fff; */
  line-height: 38px;
}
.react-quiz-container .startQuizWrapper {
  margin-top: 10px;
}

.react-quiz-container .btn {
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 11px 12px;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-quiz-container .questionWrapper .btn {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  font-size: 15px;
  display: block;
  white-space: normal;
  font-weight: 500;
  text-align: unset;
  /* background: #ed383617; */
}
.selectedcls {
  background: #ed383617;
  border: 1px solid #ff283a !important;
  accent-color: #ed3836;
}

.react-quiz-container .questionWrapper .btn.correct {
  background: green;
  color: white;
}

.react-quiz-container .questionModal .alert {
  padding: 20px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
}

.react-quiz-container .correct {
  background: green;
}

.react-quiz-container .incorrect {
  background: red;
  color: white;
}

.react-quiz-container .questionWrapper img {
  width: 100%;
}

.react-quiz-container .answerBtn {
  width: 100%;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  font-size: 15px;
  border-radius: 2px;
  line-height: 1.35135;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper h3 {
  background-color: #fafafa;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.9);
  margin: 0px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper .explanation {
  padding: 20px;
  margin: 0px 20px 20px 20px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin: 20px;
}

.react-quiz-container .result-answer {
  padding: 0px 20px;
}

.react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
}

.react-quiz-container .quiz-result-filter {
  overflow: hidden;
  width: 120px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #fefefe;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .quiz-result-filter select {
  background: transparent;
  border: none;
  font-size: 16px;
  padding: 5px;
  width: 100%;
  height: 30px;
  border: 1px solid #ffffff;
}

.react-quiz-container .tag-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection {
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.react-quiz-container .number-of-selection {
  background: #673ab7;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #3f51b5;
}

.react-quiz-container .selection-tag.multiple {
  background: #ff9800;
}
.react-quiz-container .selection-tag.segment {
  background: #3db4b9;
  margin-left: 5px;
}
.quetionnumber {
  /* text-align: center; */
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}
.qnnumber {
  color: #ff283a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.bg-white-queiz {
  background-color: #fff !important;
  border-radius: 10px;
  /* margin-top: 10%; */
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  height: 85vh;
  /* height: 500px; */
}
.questionWrapperBody h3 p {
  font-size: 17px;
  /* color: #9d9d9d !important; */
  font-weight: 800;
}
.questionWrapper {
  padding: 10px;
}
.showimg {
  margin: 0 auto;
  width: 500px;
}
.quizebg {
  top: 50px;
  bottom: 50px;
  background: #f3f3f3;
  background-image: url("../../../images/bgquiz.png");
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
}
.radioinput {
  margin-right: 10px;
  cursor: pointer;
}
.react-quiz-container .questionWrapper .btn.menu {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  color: #fff;
}
.nextQuestionBtn {
  padding: 10px 20px 10px 15px !important;
}
.showmarks {
  text-align: right;
  border-radius: 4px;
  background: rgba(255, 96, 2, 0.2);
  color: #ff283a;
  font-size: 12px;
  padding: 6px;
  margin-left: 8px;
}
.showmarks span {
  font-size: 13px;
  color: #fff;
  margin-top: -41px;
  margin-bottom: 0;
  background: #ed3836;
  font-weight: 700;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  width: 93px;
  border-top-left-radius: 15px;
  /* transform: rotate( 
49deg
 );
    position: absolute;
    right: -30px;
    padding: 1px 29px;
    letter-spacing: .3px;
    margin-top: -29px; */
}
.bg-white-queiz1 {
  /* /* background-color: #fff!important; */
  border-radius: 10px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  /* height: 86vh; */
  color: #000;
}

.bg-white-queiz1:before {
  content: "";
  float: right;
  /* height: 100%; */
  /* width: 50%; */
  shape-outside: polygon(
    2% 0%,
    100% 0%,
    100% 100%,
    2% 100%,
    50% 94%,
    76.6% 82.7%,
    94% 65.6%,
    100% 50%,
    94% 32.6%,
    76.6% 17.3%,
    50% 6%
  );
  shape-margin: 7%;
}
.quizebg .main-div {
  /* margin-top: 12px; */
  padding: 2% 4% 4% 4%;
}

.quiz-banner-section1 {
  background-image: url("../../../images/bgquiz.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #844bf521;
}

/* .pt-3, .py-3 {
  padding-top: 9rem!important;
} */
.strtquiz {
  /* float: right;
    border: none;
    background: #ff283a;
    color: #fff !important;
    padding: 7px 30px 7px 30px;
    border-radius: 6px;
    font-weight:800 ;
    font-size: 18px;
    margin: 4%; */

  border: none;
  background: #ff283a;
  color: #fff !important;
  padding: 7px 30px 7px 30px;
  border-radius: 6px;
  font-weight: 800;
  bottom: 0;
  font-size: 18px;
  margin: 4%;
  position: absolute;
  right: 0;
}
.finish-btn {
  float: right;
  margin-top: 8px;
  margin-bottom: 13px;
  padding: 12px 25px 12px 25px;
}
.swal2-title {
  text-align: center !important;
}

.eventtext {
  float: right;
  margin-top: 18px;
  /* margin-left: 6%; */
  display: flex;
}
.eventtext img {
  width: 100px;
}
.mainquizdiv {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
}
.ansbtn {
  border: none;
  background: #ff283a;
  color: #fff;
  padding: 7px 18px 7px 18px;
  margin-bottom: 15px;
  border-radius: 8px;
  margin-right: 12px;
}
.currentbtn {
  border: none;
  background: #558d36;
  color: #fff;
  padding: 7px 18px 7px 18px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.infobtnshow {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  left: 0;
  right: 0;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 18px;
}
.infobtnshow span {
  font-size: 10px;
}
.infobtnshow .answered .fa-circle {
  color: #ff283a;
}
.infobtnshow .currentquestion .fa-circle {
  color: #558d36;
}
.infobtnshow .pendingans .fa-circle {
  color: #d9d9d9;
}
.shownumberq {
  text-align: center;
  font-size: 21px;
}
.footer-btm {
  /* border: 1px #000 solid; */
  height: 63px;
  border-radius: 7px;
  padding-right: 11px;
  padding-left: 11px;
  bottom: 0;
  position: absolute;
  width: 90%;
  margin-bottom: 40px;
}
.quiztimmer {
  float: right;
  margin-top: 2%;
  color: #ed3836;
  position: absolute;
  margin-right: 12px;
  width: 60%;
  margin-left: 47px;
}

.quiztimmer #timer {
  display: flex;
  width: 100%;
}
.quizleftdetail {
  border-right: 1px solid #ccc;
  padding: 0;
}
.quizleftdetail .sectionone {
  /* display: flex; */
  margin-top: 24px;
  align-items: center;
  padding: 1px 19px 12px 60px;
  border-bottom: 1px solid #ccc;
}
.sectionone .col-md-4 {
  border-radius: 8px;
  border: 1px solid #000;
  height: 105px;
}
.sectionone .col-md-4 span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sectionone img {
  width: 100px;
}

.quizrightdetail h5 {
  padding: 3%;
  height: 62px;
  border-bottom: 1px solid #ccc;
}
.quizrightdetail div {
  padding: 3% 0% 8% 2%;
}
.quizleftdetail .sectiontwo {
  padding: 10%;
}
.sectiontwo p {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.qiz-success-page {
  padding: 4%;
  margin-top: 45px;
  background: #eff4f9;
  text-align: center;
}
.qiz-success-page .msgtitle {
  height: 80vh;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(154, 165, 176, 0.25);
  border-radius: 12px;
}
.disabledAns {
  background: #57535317;
  border: 1px solid #cccccc !important;
  accent-color: #ed3836;
}
