.workshop-card {
  height: 320px;
  /* width: 280px; */
  text-align: center;
  border-radius: 5px;
  margin-top: 40px;
  /* background: #e5e5e542; */
  box-shadow: 0px 2px 20px #0000002b;
  padding: 0px 0px 8px 0px;
  border-radius: 12px;
}
.workshop-card .card {
  border: none;
}

.workshop-card-dash {
  height: 350px;
  /* width: 280px; */
  text-align: center;
  border-radius: 5px;
  margin-top: 40px;
  /* background: #e5e5e542; */
  /* box-shadow: 0px 0px 6px 0px #ccc; */
  padding: 8px 0px 8px 0px;
}
.workshop-card:hover {
  box-shadow: 0px 0px 6px 0px #ccc;
}
.workshop-date {
  display: flex;
  /* padding-top: 10px; */
  font-size: 12px;
  font-weight: 600;
  background-color: #fff;
  color: #423f3f;
  /* border-bottom: 1px dashed #ddd; */
}
.workshop-date svg {
  margin-right: 8px;
  color: #ed3836 !important;
}
.workshop-date svg {
  font-size: 15px;
}
/* .card-img, .card-img-top{
    height: 110px;
} */
.bookmark {
  font-size: 12px;
  font-weight: 600;
  /* background-color: #0797DD; */
  background: linear-gradient(180deg, #ed3836 -12.5%, #ed3836 131.82%), #c4c4c4;
  color: #ffffff !important;
  border-radius: 30px 0px 0px 30px;
  margin-top: -14px;
  margin-left: 150px;
  padding: 5px;
  margin-bottom: 0;
}
.certificatebookmark {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff !important;
  bottom: 30px;
  right: 15px;
  padding: 7px;
  position: absolute;
  border-radius: 8px 0;
  background: #ff6002;
}
.bookmark svg {
  font-size: 12px;
  font-weight: 600;
  margin-right: 0px;
}
.workshop-title {
  padding-bottom: 2px;
  text-align: left;
}
.workshop-title p:first-child {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #000000;
  /* margin-top: 10px; */
}
.workshop-title p span {
  color: #86ccee;
}

.workshop-title span {
  font-size: 13px;
  font-weight: 400;
  /* color: #989898; */
  color: #423f3f !important;
}
.read-more-btn {
  color: #854bf6;
  border: 1px solid #854bf6 !important;
  border-radius: 5px;
  border: none;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  font-size: 14px;
  background: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.read-more-btn:hover {
  background: #854bf6 !important;
  border-color: #854bf6 !important;
  color: #fff !important;
}
.fordashboardview {
  padding: 6px 42px 20px 17px;
}
.listingimg {
  padding: 12px;
  width: 100%;
  border-radius: 17px;
}
.cardbottom {
  display: flex;
  border-top: 1px dashed #ddd;
  justify-content: space-between;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 7px 15px;
  margin-top: 0;
}
.cardbottom p {
  font-size: 12px;
  color: #666 !important;
  font-weight: 400;
  margin-bottom: 3px;
  text-align: center;
  margin-top: 5px;
}
.cardbottom p:last-child {
  border-right: none;
}

.cardbottom p i {
  color: #ed3836;
}
.cardbottom p span {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.opportunity-box {
  padding: 0 15px;
}

@media screen and (max-width: 480px) {
  .workshop-card {
    min-height: 320px;
  }
  .listingimg {
    height: 102px;
  }
  .opportunities-rightside-search {
    width: 82%;
    height: 45px;
    margin-left: auto;
    display: inline-flex;
  }
  .search-icon-workshop {
    height: 45px;
  }
  .filter-menu {
    margin-right: 35px;
  }
  .filter-menu i {
    font-size: 17px;
  }
}

@media screen and (min-width: 481px) {
  .filter-menu {
    display: none;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .opportunities-rightside-cards .col-md-4 {
    width: 50% !important;
  }
  .bookmark {
    width: max-content;
    margin-left: 111px;
    position: relative;
    padding: 5px 15px;
  }
}

.workshop-card.detailevent {
  height: 320px !important;
  margin-top: 6px;
  margin-bottom: 20px;
}
.workshop-card.detailevent .opportunity-box .cardBottom {
  bottom: 18px;
}

.no-banner {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgray;
}