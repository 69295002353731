.workshop-bottom {
  display: flex;
  background-color: #f3f3f3;
  align-items: center;
  text-align: center;
  padding: 20px 60px;
  padding-top: 60px !important;
}
.workshop-bottom h2 {
  width: max-content;
  font-size: 25px;
  margin-left: 80px;
}
.input-group {
  width: 600px;
  margin-left: 80px;
}
.form-control {
  /* height: 60px; */
  background-color: #f3f3f3;
  border: 1px solid #86757d;
  font-size: 20px;
  font-weight: 600;
}
.subscribe-btn {
  width: 200px;
  background-color: #ffb536;
  color: white;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  border: none;
  border-radius: 0px;
  padding: 10px 20px;
}
.subscribe-btn svg {
  margin-left: 10px;
}
.footer {
  background: url(../../../images/footerbg.png), #111111;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 50px;
  background-position: center;
}
.footerlogo img {
  width: 100px;
}

/* .footer-content > .col-md-12{padding-top: 120px;} */
.footer-content {
  padding-bottom: 65px;
}

.footer-section-heading {
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
  font-size: 1.2rem;
  /* margin-bottom: 25px; */
}

.contact-info-content .subheading {
  /* font-size: 1.1rem; */
  font-weight: 800;
}

li svg {
  font-size: 15px;
}

.Explore {
  background: transparent;
  height: 25rem;
  text-align: left;
  font-size: 15px;
  color: white;
  margin-top: 40px;
  padding: 30px;
}
.explore-text {
  padding: 20px 0px;
  line-height: 30px;
}
.explore-btn {
  background-color: #ffb536;
  color: white;
  font-size: 20px;
  text-align: center;
  border: none;
  border-radius: 0px;
  padding: 10px 20px;
}
.contact-info {
  background: transparent;
  height: 25rem;
  text-align: left;
  font-size: 15px;
  color: white;
  border: 0;
  padding: 30px;
  line-height: 1;
}
.contact-info-content li {
  color: #fff;
  line-height: 36px;
}
.contact-info-content li:first-child {
  padding-top: 0px !important;
}

.contact-info-content li a {
  color: white;
  text-decoration: none;
}
.contact-info-content li a img {
  padding-right: 10px;
}

.careers {
  background: transparent;
  height: 25rem;
  text-align: left;
  font-size: 15px;
  color: white;
  padding: 30px;
}
.careers p {
  margin-top: 0px;
}

.careers p:first-child {
  margin-top: 0px;
}
.careers li svg {
  margin-right: 10px;
}

.copyright {
  text-align: center;
  line-height: 3;

  background: #fff;
}
.copyright p {
  font-size: 13px;
  margin-bottom: 0px;
}
/* h3 span
{
    color: #155fc2 !important;
} */
.footer-body div {
  color: #fff !important;
}
.career-content li p {
  color: #fff !important;
}

.career-content li svg,
.career-content li {
  font-size: 16px;
}
.ftr-read-more {
  font-size: 11px;
  color: #fff !important;
  margin-top: 12px;
  line-height: 20px;
  text-align: justify;
  width: 90%;
}

.explore-text {
  color: #fff !important;
}
.subscriberheading {
  text-align: left;
  font-size: 19px;
  line-height: 58px;
}

.newsletter-form input[type="email"] {
  padding-left: 20px;
  font-size: 16px !important;
  height: 57px;
  border: 1px solid #dbdbdb !important;
}
.newsletter-form input[type="email"]::placeholder {
  color: #666 !important;
}
.newsletter-form button {
  background: #5131b6;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 10px 10px 24px !important;
  height: 57px;
  width: 100%;
  border-radius: 5px;
}

/*  .top-ftr{    background: #f3f3f3;
        padding-top: 40px;
        } */

.career-content li p a {
  color: #fff;
}
.career-content li p a:hover {
  color: #f1f1f1;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .subscriberheading {
    line-height: 30px;
    margin-bottom: 25px;
  }
  .footer {
    padding-top: 100px;
    background-position: right;
    margin-top: 60px;
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.sociallinks {
  width: 30px;
  margin-right: 15px;
  margin-top: 25px;
}

li {
  list-style: none;
}
