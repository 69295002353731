.workshop {
  margin-top: 10px;
  text-align: center;
  align-items: center;
}
.workshop-top h1 {
  font-size: 60px;
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.workshop-middle {
  text-align: center;
  /* padding: 40px; */
  align-items: center;
}
.carousel-item {
  text-align: center;
}
.mt-20 {
  margin-top: 60px;
}
