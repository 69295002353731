.topimagetnc {
  height: 415px;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  background-image: linear-gradient(to bottom, transparent, #c6a3e400),
    url("../../images/termandconditions.png");
  object-fit: cover;
  margin-bottom: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 4%;
}
.termandcondition p {
  text-align: justify;
  font-size: 15px;
  color: #5f5e5e !important;
  letter-spacing: 0.3px;
}

.privacypolicy {
  height: 415px;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  background-image: linear-gradient(to bottom, transparent, #c6a3e400),
    url("../../images/privacypolicy.png");
  object-fit: cover;
  margin-bottom: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 4%;
}
.nav-body-reg {
  position: sticky;
  width: 100%;
  min-height: 80px;
  top: 0;
  z-index: 99999;
  background: #fff;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
  display: block;
  margin-left: 0;
  top: 62px;
  display: flex;
}

.talignleft {
  text-align: left;
}
.dashboard .talignright {
  text-align: right;
  display: block;
  justify-content: unset;
}
.talignright {
  text-align: right;
  display: -webkit-inline-box;
  justify-content: unset;
}
.topimgteam {
  width: 100%;
  margin-right: 20px;
  /* height: 100px; */
}
.teamaddform {
  position: relative;
  margin-top: 5%;
}
.success-page {
  margin-top: 7%;
  margin-bottom: 10%;
  margin-left: 13px;
  margin-right: 13px;
  text-align: center;
}
/* team show table */
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 6px;
  margin-bottom: 15px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background: #f5f5f5;
  border-bottom: 0;
  color: #4f5e74;
}
.successpagemsg {
  color: #18181b;
  font-size: 25px;
  width: 100%;
  margin-bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  float: right;
}

.invalid-data {
  border: 1px solid;
}
.notfilled {
  text-align: center;
  color: red;
  width: 100%;
}
.modal.show .modal-dialog {
  transform: none;
  top: 20%;
  z-index: 999999999;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 600px;
  }
}
@media (max-width: 420px) {
  .nav-body-reg {
    position: relative;
  }
}
.button input[type="radio"] {
  display: none;
}

/* input[type="radio"]:checked + label {
  border: 1px solid red;
} */

.paynow {
  /* height: 20px; */
  width: 100px;
  margin: 1px -52px 5px 2px;
  position: relative;
  top: 54%;
  left: 42%;
}

.planoptions {
  padding: 5px;
  background: #ff283a1c;
  border-radius: 5px;
  margin-bottom: 10px;
}
.loadershow {
  position: absolute;
  display: flex;
  /* margin-left: 40%; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000000000 !important;
  height: 100vh;
  background: #fff;
}
.loadershow img {
  width: 100px;
  margin: auto;
}
.loader {
  top: 30%;
  left: 45%;
  right: 50%;
  height: auto;
  position: fixed;
}
.successimg {
  width: 300px;
  margin: auto;
}
