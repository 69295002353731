/* Overall Container */
.festival-main-container {
  font-family: "Inter", Arial, sans-serif;
  color: #333333;
  padding: 0;
  margin-top: 100px;
  background-color: #ffffff;
}

.festival-main-content {
  width: 100%;
  margin-top: -300px;
  margin-bottom: 80px;
  padding-left: 6%;
  padding-right: 6%;
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333333;
  margin-bottom: 10px;
  margin-top: 50px;
}

@media screen and (max-width: 1280px) {
  .festival-main-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .festival-main-content {
    margin-top: 0px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.loader-container {
  width: 100%;
  height: 97vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slider Section */
.slider-image {
  width: calc(100% - 40px);
  height: auto;
  object-fit: contain !important;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .slider-image {
    width: 100% !important;
  }
}

.festival-main-content .swiper-wrapper {
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .festival-main-content .swiper-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Sticky Tabs */
.sticky-tabs {
  position: sticky;
  top: 80px;
  z-index: 10;
  background-color: #ffffff;
  padding: 10px 20px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  gap: 15px;
  width: max-content;
  max-width: 100%;
  flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
  border-radius: 40px;
  border: 1px solid #c8c8c8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sticky-tabs button {
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333333;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.3s ease;
}

.sticky-tabs button:hover {
  background-color: #f92c2c;
  color: #ffffff;
}

.sticky-tabs button.active {
  background-color: #f92c2c;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .sticky-tabs {
    padding: 10px 15px; /* Adjust padding for smaller screens */
    gap: 10px; /* Reduce gap between buttons */
  }

  .sticky-tabs button {
    padding: 8px 15px; /* Reduce padding inside buttons */
    font-size: 0.8rem; /* Adjust font size */
  }
}

/* Content Sections */
.section {
  margin: 20px;
  padding: 20px;
  background-color: #f5f5fa;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .section {
    margin: 15px 0;
    padding: 15px;
  }
}

.opportunity-section {
  display: flex !important;
  width: 100% !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  gap: 5% !important;
  flex-wrap: wrap !important;
  flex-shrink: 0 !important;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .opportunity-section {
    flex-direction: column !important;
    gap: 20px !important;
    padding: 0px;
  }
}

/* Opportunities Section */
.opportunity-cards {
  width: 30%;
  min-width: 250px;
}

@media screen and (max-width: 768px) {
  .opportunity-cards {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
  .workshop-card.detailevent {
    height: 400px !important;
  }
}

/* Festival Details Section */
.details p {
  font-size: 1rem !important;
  color: #333333 !important;
  line-height: 2.2 !important;
  text-align: justify !important;
}

/* Gallery Section */
.gallery-section {
  margin: 20px;
  padding: 20px;
  background-color: #f5f5fa;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .gallery-section {
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .container {
    max-width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 480px) {
  .sticky-tabs {
    width: 100%;
    flex-wrap: nowrap;
    padding: 4px 8px;
  }

  .sticky-tabs button {
    padding: 4px 8px;
    font-size: 0.6rem;
  }

  .festival-main-container {
    margin-top: 70px;
  }
}
