.ant-table-filter-column {
  display: flex;
  padding: 6px 15px 6px 15px;
  justify-content: space-between;
  background: #d1d4e180;
  border-radius: 8px;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  color: #4f5e74;
  flex: 1 1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
.ant-table-thead > tr > th {
  background: #f5f5f5;
  color: #4f5e74;
  position: relative;
  z-index: 1;
  color: #4f5e74;
  flex: 1 1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600 !important;
}
.topboxflex {
  padding: 20px 20px 20px 26px;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.teamname {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* or 134% */

  color: #18181b;
}
.teamname span {
  background: rgba(255, 96, 2, 0.2);
  border-radius: 4px;
  padding: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ff6002;
}
.teamname div {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #51636f;
}
.topboxflex .pr-3 {
  cursor: pointer;
  padding: 8px 20px;
}
.topboxflex .pr-3.active {
  padding: 8px 20px;
  background: #ffd4d8;
  border-radius: 18px;
  margin-right: 15px;
  color: #ff283a;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.d-flex .imgeshow {
  width: 50px;
}
.d-flex span .fa-check-circle:before {
  content: "\f058";
  color: green;
  font-size: 25px;
  margin-right: 12px;
}
.d-flex span .fa-times-circle:before {
  content: "\f057";
  color: #ff283a;
  font-size: 25px;
  margin-right: 12px;
}
.d-flex span .fa-download:before {
  color: #ff283a;
  font-size: 25px;
}
.anticon svg {
  font-size: 12px;
  fill: #000;
}
.ant-table-column-sorters {
  display: block;
  float: left;
}
.ant-table-column-sorter {
  margin-left: 10px;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  top: -6px;
  position: relative;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #ff283a;
}
.modal-content .form-control.radiocss {
  height: auto;
}
.roundStatus {
  text-align: center;
  justify-content: center;
}
.roundStatus span {
  cursor: pointer;
  width: 34px;
}
.roundStatus span img {
  width: 25px;
}
.roundAction {
  float: right;
}
.roundAction span img {
  width: 25px;
  margin-right: 5px;
}
.roundAction span {
  border-right: 1px solid #51636f;
  padding-right: 14px;
  /* width: 100%; */
  cursor: pointer;
  height: 22px;
}
.roundAction span.dotcss {
  margin-left: 20px;
}
.align-center {
  text-align: center;
}
.tabcount span {
  display: none;
}
.active.tabcount span {
  display: block;
}
.grayscale {
  filter: grayscale(100%);
}
.roundAction button {
  border: none;
  background-color: #eff4f9;
  display: flex;
  cursor: pointer;
}
.roundAction button img {
  width: 22px;
  margin-right: 6px;
}
.rounddetail-topheading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  text-transform: capitalize;
  color: #51636f;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 27px;
  top: -6px;
}
.ant-pagination-options {
  display: none;
}
.resultuploadbtn {
  float: right;
  margin-top: -10px;
  margin-right: 12px;
}
.right-side-btn {
  margin-top: -34px;
  padding: 22px 0px 25px 12px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.swal2-popup {
  display: flex !important;
  align-items: center !important;
  flex-flow: column !important;
}