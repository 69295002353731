.eligibility-form-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 15px;
  border: 2px solid #d7d6d6;
  border-radius: 5px;
  padding: 10px;

  .input-field {
    flex: 0 1 calc(50% - 16px);
  }
}
