@import "../../utils/Colors/index.css";

.opportunities {
  text-align: center;
  padding-right: 0px;
}
.heading {
  text-align: center;
}
.heading h1 {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.opportunities-content {
  text-align: center;
}
.opportunities-btn-area {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  padding: 40px 80px;
}
svg {
  /* Margin-right: 10px; */
  font-size: 20px;
}
.Opportunities-btn {
  color: #ffff;
  height: 60px;
  width: 200px;
  background-color: #079ae0;
  font-size: 20px;
  font-weight: 600;
  border-radius: 40px;
  border: none;
}
.Workshop-btn {
  color: #6c757d;
  background-color: #ffff;
  height: 60px;
  width: 200px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 40px;
  border: none;
}
.opportunities-card-area {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  padding: 40px 80px;
}
.opportunities .ant-tabs-nav-list {
  padding: 0px 0px 0px 0px;
  font-size: 14px;
  background: #f4f5f8;
  margin: auto;
  border-radius: 30px;
  border: 1px solid #000;
  font-family: var(--primaryFontColor);
  line-height: 40px;
  padding: 5px;
}
.opportunities .ant-tabs-tab.ant-tabs-tab-active {
  color: #fff;
  font-size: 14px;
  background-color: var(--primaryColor);
  border: 1px solid #5736ba;
  border-radius: 30px;
  font-family: var(--primaryFontColor);
  line-height: 40px;
}
.opportunities .ant-tabs-tab.ant-tabs-tab-active span {
  color: #fff;
  font-size: 16px;
  text-align: center;
}
.opportunities .ant-tabs-tab span {
  color: #848484;
  font-size: 18px;
  font-family: var(--primaryFontColor);
  text-align: center;
}
.opportunities .ant-tabs-tab {
  padding: 4px 40px 3px 40px;
  margin: 0;
}
.opportunities .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.ant-tabs-ink-bar {
  background: none !important;
}

.ant-tabs-nav {
  padding-bottom: 20px;
}

.opportunities .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  height: 100px;
}

.opportunities-content .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.opportunities-card .card-body {
  padding: 0px 0px 1.7rem 22px;
  text-align: left;
}
.opportunities-card-btn button {
  background-color: #ff9900;
  border-radius: 40px;
  border: none;
  align-items: center;
  padding: 13px 25px;
  position: relative;
  margin-top: -26px;
  font-size: 14px;
}
.opportunities-bottom a {
  text-decoration: revert;
}
.card-title.h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
  color: #191f23;
}
