.register-body {
  padding: 40px 28px;
  text-align: center;
  border-radius: 15px;
  background-color: #000;
}
.register-body input[type="text"].form-control {
  background: rgba(255, 255, 255, 0) !important;
  height: 48px;
  border: none !important;
  font-size: 16px !important;
  border: 1px solid #dbdbdb !important;
  color: #fff !important;
  font-weight: 400 !important;
  padding: 10px;
}
.register-body .material-icons {
  color: #fff !important;
}
.register-body h1 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}
.register-body h1 Span {
  color: #a357e0;
}
.register-body input {
  background-color: #ffff !important;
}
.register-body button {
  background-color: #fff;
  color: #a357e0;
  border: none;
  font-size: 20px;
  border: 1px solid #a357e0;
  font-weight: 600;
  padding: 10px 150px;
  border-radius: 20px;
}
.register-body p {
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 600;
}
.register-body-form {
  height: 50px;
  border-radius: 8px;
  padding: 0 15px 0 50px;
  margin-bottom: 0;
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid #9e9e9e !important;
  color: #176ede;
  font-size: 14px;
  font-weight: 600;
}
.material-icons {
  /* font-weight: 400; */
  font-style: normal;
  font-size: 17px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  position: absolute;
  color: #9e9e9e;
  left: 77px;
  /* top: 0; */
  /* bottom: 0; */
  margin-top: -33px;
  height: 23px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.regimg {
  width: 420px;
  height: 333px;
  margin-top: 26%;
}
.bg {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  top: 50px;
  bottom: 50px;
}
.main-div {
  padding: 2%;
  border-radius: 23px;
}
.errormsg {
  color: #eb5555 !important;
  margin: 10px 0px;
  font-size: 13px !important;
  font-weight: 600;
  text-align: center;
}
.radiobtnregister input {
  font-size: 12px !important;
  height: 22px !important;
  margin-right: -40px;
}
.radiobtnregister {
  padding: 12px 12px 12px 12px !important;
  display: flex;
}

.register-body .errormsgborder {
  border: 2px solid red !important;
  animation: shake 0.5s;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
/* .ant-select-selector
  {
    height: 46px !important;
    border: none !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input
  {
    height: 46px;
    border: none;
  }
  .ant-select-selector:focus
  {
      border: none !important;
  } */
/* .fa-university
  {
    margin-top: 9px !important;
  } */

/* .css-g1d714-ValueContainer
  {
    margin-left: 30px;
  } */

.uldatacollage {
  position: absolute;
  /* margin-top: 8%; */
  width: 81%;
  text-align: left;
  padding: 0px 0px 0px 0px;
  background: #fff;
  border: 1px solid #9e9e9e;
  height: 200px;
  border-radius: 5px;
  overflow: auto;
}
.uldatacollage li {
  padding: 5px 6px 5px 5px;
  border-bottom: 1px solid #cccccc;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.uldatacollage li:hover {
  padding: 5px 6px 5px 5px;
  background: #c5c5c55e;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  cursor: pointer;
}
.autoheight {
  position: absolute;
  /* margin-top: 8%; */
  width: 81%;
  text-align: left;
  padding: 0px 0px 0px 0px;
  background: #fff;
  border: 1px solid #9e9e9e;
  height: auto;
  z-index: 9;
  border-radius: 5px;
  overflow: auto;
}
.autoheight li {
  padding: 5px 6px 5px 5px;
  border-bottom: 1px solid #cccccc;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.createcolor {
  color: #176ede;
  font-weight: 400;
  /* font-size: 20px; */
}
.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
  margin: 1em 1em 0 !important;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 600;
  text-align: left !important;
  text-transform: none;
  word-wrap: break-word;
}
.register-body input {
  background-color: #ffff !important;
  padding-left: 45px !important;
}
.register-body h1 {
  color: #fff !important;
}
.register-body button {
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 20px;
  border: 1px solid #a357e0;
  font-weight: 600;
  padding: 10px 150px;
  border-radius: 10px;
  width: 100%;
}
