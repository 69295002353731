.contact-us h1 {
  font-size: 40px;
  font-weight: 800;
  padding: 20px 0px;
}
.contact-us h1 Span {
  color: #ffb536;
}

.contact-us-left {
  align-items: center;
}
.contact-us-left a {
  color: #997591;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}
.contact-us-left a p {
  margin: 0px;
}
.contact-us-left a img {
  background-color: #ffb536;
  border-radius: 50px;
}

.contact-us-right {
  text-align: center;
}
.contact-us-right input {
  background-color: #ffff;
}
.contact-us-right button {
  background-color: #ffb536;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 40px;
  border-radius: 20px;
}
.contact-us-right p {
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 600;
}
