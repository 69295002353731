#user-wrapper {
  align-items: stretch;
  /* display: flex; */
  width: 100%;
}
.user-left-sidebar {
  float: left;
  min-width: 264px;
  max-width: 264px;
  background: #5736ba;
  min-height: 100vh;
}
.right-content {
  /* float:right;
    width: 100%; */
  min-width: 0;
  min-height: 100vh;
  background: #f5f7fb;
  flex-direction: column;
  overflow: hidden;
}

.user-left-sidebar h4 {
  padding: 25px;
}
.user-left-sidebar ul {
  padding: 0px;
}
.user-left-sidebar ul li:first-child {
  background: #6646c5;
  display: block;

  font-size: 16px;
}

.user-left-sidebar ul li {
  font-size: 16px;
  transition: 0.5s;
  border-bottom: 1px solid #6747c5;
}

.user-left-sidebar ul li a {
  color: #fff;
  display: block;
  transition: 0.5s;
  padding: 15px 25px;
}
.user-left-sidebar ul li a:hover {
  transform: translateX(10px);
  transition: 0.5s;
}
.header-right-icon a {
  background: #5736ba;
  color: #fff !important;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  margin-left: 8px;
}
.header-right-icon a:hover {
  color: #fff;
}
.dashboard-header-top {
  background: #f3efff;
  padding: 10px 0;
}
.dashboard-top-header {
  box-shadow: 0 0 2rem 0 rgb(33 37 41 / 10%);
}
.content {
  padding: 7rem 1rem 1.5rem;
}
.collapsed-in {
  display: none;
}
.dashboard .ant-tabs-nav,
.dashboard .heading,
.dashboard .days-left {
  display: BLOCK !important;
  padding-bottom: 0px;
  top: 1px;
}
.dashboard-copyright {
  background: #ececec;
  padding: 5px;
  height: auto !important;
}

.user-sticky-sidebar {
}
.main-content {
  height: 170px;
  width: 100%;
  box-shadow: 0px 2px 4px #0000005e;
}
.imagesize {
  width: 100%;
}
.userdetail p {
  font-size: 25px;
}
.floatright {
  float: right;
  margin-top: 10px;
  background-color: #5736ba;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 10px;
}
.errmsg {
  color: red !important;
  text-align: center;
}
.logoutbtn {
  float: right;
  margin-top: 10px;
  background-color: red;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 10px;
}
.registerbox {
  border: 1px solid #ccc;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 28px;
  justify-content: space-between;
}
.registerbox .centerbox {
  margin-left: -60px;
}
.searchbox {
  padding: 5px 50px 5px 15px;
  margin: 0;
  box-sizing: border-box;
  height: 50px;
  border: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 5px rgb(19 74 159 / 15%);
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  color: #000;
}
.topbtnview {
  background: #f5f7fb;
  box-shadow: 0 2px 5px rgb(19 74 159 / 15%);
  border-radius: 8px;
  width: 90px;
  height: 40px;
  color: #5736ba;
  font-weight: 600;
  font-size: 17px;
  margin-left: 10px;
  align-items: center;
  border: 1.5px solid #ccc;
  cursor: pointer;
  transition: 0.3s;
}

.topbtnview.active {
  background: #9f4ef7;
  box-shadow: 0 2px 5px rgb(19 74 159 / 15%);
  border-radius: 8px;
  width: 120px;
  height: 50px;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  margin-left: 10px;
  align-items: center;
  border: 1.5px solid #fff;
  cursor: pointer;
  transition: 0.3s;
}
.tp20 {
  margin-top: 20px;
}
.custom-classname.react-toggle--checked .react-toggle-track {
  background-color: #9f4ef7;
}
.togglespan {
  position: relative;
  top: -7px;
  right: 15px;
}
.registerbox p {
  margin-bottom: 5px;
}
.menubtn a {
  color: #fff;
}

.register-body {
  padding: 40px 28px;
  text-align: center;
  border-radius: 15px;
}
.register-body h1 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}
.register-body h1 Span {
  color: #a357e0;
}
.register-body input {
  background-color: #ffff !important;
}
.register-body button {
  background-color: #fff;
  color: #a357e0;
  border: none;
  font-size: 20px;
  border: 1px solid #a357e0;
  font-weight: 600;
  padding: 10px 150px;
  border-radius: 20px;
}
.register-body p {
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 600;
}
.register-body-form-edit {
  height: 50px;
  border-radius: 8px;
  padding: 0 15px 0 15px;
  margin-bottom: 0;
  box-sizing: border-box;
  box-shadow: none;
  border: 1px solid #9e9e9e !important;
  color: #176ede;
  font-size: 14px;
  font-weight: 600;
}
/* .material-icons {
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    position: absolute;
    color: #9e9e9e;
    left: 77px;

    margin-top: -33px;
    height: 23px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
} */
.regimg {
  width: 420px;
  height: 333px;
  margin-top: 26%;
}
.bg {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  top: 50px;
  bottom: 50px;
}
.main-div {
  padding: 2%;
  border-radius: 23px;
}
.errormsg {
  color: #ff283a !important;
  margin: 10px 0px;
  font-size: 13px !important;
  font-weight: 600;
  text-align: center;
}
.radiobtnregister input {
  font-size: 12px !important;
  height: 22px !important;
  margin-right: -40px;
}
.radiobtnregister {
  padding: 12px 12px 12px 12px !important;
  display: flex;
}

.register-body .errormsgborder {
  border: 2px solid red !important;
  animation: shake 0.5s;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.talignright {
  text-align: right;
}
.viewsubmition {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  color: #fff !important;
  margin-top: 1px;
  margin-right: 10px;
}
.approved {
  color: green !important;
}
.pending {
  color: red !important;
}
.userdetail p {
  font-size: 15px;
}
.registeredby {
  color: #854bf6;
}
.ant-table-wrapper {
  overflow: auto;
}
.successmsg {
  color: green !important;
}
.membersuccess {
  color: #ffffff !important;
  margin-top: 18px;
  font-size: 18px;
}
.membersuccessmobile {
  color: #a74ff7 !important;
  margin-top: 18px;
  font-size: 18px;
}
.cancelbtn {
  background: red;
  border: 1px solid red;
}

@media screen and (max-width: 480px) {
  .signup-bg .register-body {
    margin: 90px 12px 40px;
  }
  .signup-bg .register-body-form {
    padding: 0 15px 0 38px;
    font-size: 14px !important;
  }
  .signup-bg .register-body input {
    font-size: 14px !important;
  }

  .signup-bg .material-icons {
    font-size: 14px;
    left: 63px;
    margin-top: -32px;
  }
  .signup-bg .register-body p {
    font-size: 16px;
    margin-bottom: 0;
  }
  .radiobtnregister input {
    margin-right: 0;
    height: 15px !important;
    margin-top: 3px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .signup-bg {
    margin-top: 60px;
    padding: 40px;
  }
  .signup-bg .main-div {
    margin-left: 0 !important;
  }
  .signup-bg .material-icons {
    font-size: 14px;
    left: 59px;
    margin-top: -33px;
  }
}

@media screen and (min-width: 320px) and (max-width: 786px) {
  .registerbox .centerbox {
    margin-left: 0px !important;
  }
  .registerbox .col-md-2 {
    border: 3px solid #f1f1f1;
    border-radius: 8px;
    height: 280px !important;
    margin-bottom: 22px !important;
  }
  .registerbox .talignright {
    text-align: left !important;
    margin-top: 6px;
  }
  .talignright button {
    margin-left: 0px !important;
  }
  .registerbox .justify-content-end {
    justify-content: space-between !important;
  }
}
@media screen and (max-width: 1023px) {
  .show-pw {
    right: 54px;
  }
  .bg {
    margin-top: 62px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .register-body-form {
    height: 50px !important;
    padding: 0 15px 0 45px;
  }
  .bg .material-icons {
    margin-top: -31px;
    left: 54px;
    font-size: 16px;
  }
  .login-body button {
    padding: 7px 20px;
    font-size: 16px;
  }
  .signup-bg .register-body button {
    font-size: 16px;
    padding: 7px 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .bg .offset-md-4 {
    margin-left: 24.333333%;
  }

  .bg .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 50.333333%;
  }
  .signup-bg .col-md-5 {
    max-width: 65.666667% !important;
    margin-left: 17.333333% !important;
  }
  .signup-bg {
    margin-top: 62px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .signup-bg .material-icons {
    left: 63px;
    margin-top: -32px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .bg .offset-md-4 {
    margin-left: 30.333333%;
  }

  .bg .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 39.333333%;
  }
  .register-body-form {
    height: 50px !important;
    padding: 0 15px 0 45px;
  }
  .login-body button {
    padding: 7px 20px;
    font-size: 16px;
  }
  .bg .material-icons {
    margin-top: -31px;
    left: 69px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .signup-bg .offset-sm-4 {
    margin-left: 28.333333%;
  }
  .signup-bg {
    margin-top: 61px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.ant-tabs-nav-list {
  position: inherit !important;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
  /* border: 1px solid #efefef; */
  /* background-color: #f8f9fa; */
  /* border-radius: 10px; */
  padding: 15px;
  border-top-left-radius: 0px;
}

.ant-tabs {
  box-sizing: border-box;
  width: 100%;
  /* text-align: left; */
  padding: 0;
  color: rgb(132 75 245);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: flex;
  overflow: hidden;
}
.quiz-settings {
  display: flex;
  width: 100%;
  background: #fff;
  padding: 14px;
  border-radius: 13px;
  border: 1px solid #efefef;
}
.mr-20 {
  margin-right: 20px;
}
.setting-row .row {
  margin-bottom: 20px;
}
.setting-row label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  margin-right: 20px;
  /* color: #636060; */
  font-weight: 500;
}
/* .ant-tabs-tab
    {
        box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px inset;
    } */
.addquizsection .ant-tabs-nav {
  margin: 0px !important;
}

.addquizsection .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 75px;
}

.addquizsection .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-size: 18px;
  margin-right: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.addquizsection .ant-tabs-tab.ant-tabs-tab-active {
  background: linear-gradient(180deg, #7942f3 -12.5%, #af4bff 131.82%), #c4c4c4;
  color: #fff !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.removeicon .fa-trash {
  font-size: 20px;
}
.removeiconq {
  position: absolute;
  float: right;
  right: 17px;
  top: 0;
}
.ant-tabs-tabpane form {
  color: rgba(0, 0, 0, 0.85) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: inherit !important;
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
}

.addquizsection .ant-tabs-content-holder {
  padding: 10px 15px 30px 15px !important;
  background: #fafafa !important;
}

.buttonstyle {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.choicecss {
  width: 100%;
}

.radiobtn {
  position: relative;
  display: block;
  label {
    display: block;
    background: lighten($accentcolor, 30%);
    color: $darkcolor;
    border-radius: 5px;
    padding: 10px 20px;
    border: 2px solid lighten($accentcolor, 20%);
    margin-bottom: 5px;
    cursor: pointer;
    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 11px;
      top: 11px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background: lighten($accentcolor, 15%);
    }
    &:before {
      background: transparent;
      transition:
        0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
        0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
      z-index: 2;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: center;
      width: 0;
      height: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
    }
  }
  input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;
    &:checked + label {
      background: lighten($accentcolor, 15%);
      animation-name: blink;
      animation-duration: 1s;
      border-color: $accentcolor;
      &:after {
        background: $accentcolor;
      }
      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@keyframes blink {
  0% {
    background-color: lighten($accentcolor, 15%);
  }
  10% {
    background-color: lighten($accentcolor, 15%);
  }
  11% {
    background-color: lighten($accentcolor, 20%);
  }
  29% {
    background-color: lighten($accentcolor, 20%);
  }
  30% {
    background-color: lighten($accentcolor, 15%);
  }
  50% {
    background-color: lighten($accentcolor, 20%);
  }
  45% {
    background-color: lighten($accentcolor, 15%);
  }
  50% {
    background-color: lighten($accentcolor, 20%);
  }
  100% {
    background-color: lighten($accentcolor, 15%);
  }
}

.settingcss {
  text-transform: capitalize;
}
.text-right {
  text-align: right;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.hostingpage {
  padding: 3%;
  background: #eff4f9;
  position: relative;
}
.hostingpage .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  border-bottom: 2px solid #ff283a !important;
  /* identical to box height, or 117% */
  /* padding-bottom: 15px; */

  color: #ff283a !important;
}
.hostingpage .ant-tabs-tab {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  padding-bottom: 15px;
  margin-bottom: 10px;

  /* identical to box height, or 117% */

  color: #51636f !important;
}
.hostingpage .ant-tabs-nav,
.dashboard .heading,
.dashboard .days-left {
  display: BLOCK !important;
  padding-bottom: 0px;
  align-items: start !important;
  top: 1px;
  margin: 0px !important;
}
.myhosting-titiles a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0px;
  /* identical to box height, or 133% */
  margin-top: 0px;

  color: #18181b;
}
.user-logo {
  width: 10%;
}
.hostingpage .insideTab .ant-tabs-tab.ant-tabs-tab-active {
  padding: 8px 0px 8px 20px;
  background: #fff;
  border-radius: 8px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.hostingpage .insideTab .ant-tabs-tab {
  padding: 8px 20px 8px 20px;
  /* background: #fff; */
  border-radius: 10px;
  /* margin-right:   15px; */
}
.hostingpage .insideTab .ant-tabs-tab-btn {
  padding: 1px 10px 3px 3px;
  margin-right: 10px;
}
.hostingpage .insideTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  transition: 0.5s;
  /* padding: 5px 30px 8px 30px; */

  color: #ff283a !important;
  border-bottom: none !important;
}
.hostingpage
  .insideTab
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn
  .list-icon-img {
  filter: invert(0.4) sepia(1) hue-rotate(312deg) saturate(936%);
}
.hostingpage .insideTab .ant-tabs-tab .ant-tabs-tab-btn .list-icon-img {
  width: 15px;
  margin-right: 6px;
  margin-top: -2px;
}
.dashboard-BG {
  background-color: #f8f9fa !important;
}
.registerbox .col-md-2 {
  border: 3px solid #f1f1f1;
  border-radius: 8px;
  height: 132px;
}
@media screen and (min-width: 768px) {
  .registerbox .col-md-2 {
    max-width: 11%;
  }
  .registerbox .col-md-2 div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .registerbox .col-md-2 .imgsize {
    width: 130px;
    padding: 10px;
  }
  .registerbox .col-md-4 {
    flex: 0 0 39%;
    max-width: 39.5%;
  }
  .dashboardicon {
    width: 14px;
  }
}
.registerbox .col-md-2 .imgsize {
  margin-top: 0px !important;
}

.team_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #770155;
}
.displayflex {
  display: flex;
  justify-content: space-between;
}
.dashboard .registerbox .talignright {
  text-align: right;
  /* display: flex  !important; */
  justify-content: end;
  align-content: center;
  flex-wrap: wrap;
  align-items: end;
  text-align: right;
}
.dashboard .registerbox .hostingcls.talignright {
  /* text-align: right;
    display: flex  !important;
    justify-content: end;
    align-content: center;
    flex-wrap: wrap;
    align-items: end;
    text-align: right; */
}
.talignright button {
  margin-left: 10px;
}
.searchandDropdown {
  background: #fff;
  padding: 6% 4% 1% 5%;
}
.searchandDropdown div input {
  box-shadow: none;
  padding-left: 30px;
}
.searchandDropdown div input :focus {
  border: none;
}
.right-dropdown {
  margin-top: 10px;
  margin-right: 10px;
}
.dropdownContainer {
  display: flex;
  justify-content: flex-end;
}
.searchIcon {
  position: absolute;
  margin-top: 15px;
  font-size: 18px;
}
.actionBtn {
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 15px;
}
.stautusBtn {
  border-radius: 36px;
  padding: 5px 13px;
  font-size: 12px;
  margin-top: -5px;
  margin-left: 10px;
}
.stautusBtn .fa-circle {
  font-size: 8px;
  margin-right: 2px;
}
.closed {
  background-color: rgba(81, 99, 111, 1) !important;
}
.shareicon {
  margin-left: 12px;
  font-size: 27px;
  color: #ed3836;
}
input:focus {
  outline: none;
}
.fa-th-list.myhostingicon {
  color: #fff;
  background: red;
  padding: 4px;
  margin-right: 8px;
  border-radius: 5px;
  font-size: 14px;
}
.home.nav-link.active {
  color: #ff283a !important;
  border-bottom: 2px solid;
}
.home.nav-link.active span img {
  filter: invert(0.4) sepia(1) hue-rotate(312deg) saturate(936%);
  width: 15px;
  margin-right: 6px;
  margin-top: -2px;
}
.home.nav-link span img {
  width: 15px;
  margin-right: 6px;
  margin-top: -2px;
}
.nav-link {
  height: 49px;

  /* margin-top: 17px; */
}
.bg-light {
  background-color: #ffffff !important;
  padding-bottom: 0 !important;
  height: 70px !important;
  z-index: 9999 !important;
}
.position_absolute {
  position: absolute;
  right: 4%;
  padding-top: 32px;
  display: flex;
  z-index: 99;
}
.position_absolute button,
.position_absolute div {
  margin-left: 4px;
}
.position_absolute button {
  margin-top: 2px;
}
.d-flex p {
  color: #ff283a !important;
  text-decoration: underline;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.d-flex p a {
  color: #ff283a !important;
  text-decoration: underline;
  margin-top: 15px;
  margin-right: 10px;
}
.transparentbtn {
  background-color: transparent !important;
  border: 1px solid;
  color: red !important;
  font-weight: 700;
}
.position_absolute .css-b62m3t-container .css-13cymwt-control {
  background-color: #51636f;
  border-color: #51636f;
  color: #fff !important;
  /* margin-top: 5px; */
  width: 230px;
}
.position_absolute
  .css-b62m3t-container
  .css-13cymwt-control
  .css-1fdsijx-ValueContainer
  .css-1jqq78o-placeholder {
  color: #fff !important;
  width: 230px;
}
.position_absolute
  .css-b62m3t-container
  .css-13cymwt-control
  .css-1fdsijx-ValueContainer
  .css-1dimb5e-singleValue {
  color: #fff;
}
.position_absolute
  .css-b62m3t-container
  .css-t3ipsp-control
  .css-1fdsijx-ValueContainer
  .css-1dimb5e-singleValue {
  color: #000;
}
.position_absolute .css-b62m3t-container {
  width: 230px;
  margin-top: 5px;
}
.css-1nmdiq5-menu {
  z-index: 99999999 !important;
}
.position_absolute .css-b62m3t-container .css-1nmdiq5-menu {
  width: 230px;
  /* margin-left: -50px; */
}
.register_by {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #191f23;
}
.position_absolute .css-t3ipsp-container .css-13cymwt-control {
  margin-top: 5px;
}

.regsisterationpage.hostingpage {
  padding-left: 5%;
  padding-right: 4%;
}
.remove-round-member {
  color: #fff;
  min-width: 100px;
  border-radius: 4px;
  padding: 9px 18px;
  font-size: 15px;
  font-weight: 200;
  /* width: 40%; */
  border: 4px solid #fff;
  margin-left: auto;
  background: red;
}
.editprofilepass #basic_oldpass,
.editprofilepass #basic_password,
.editprofilepass #basic_confirmpass {
  color: #000 !important;
}
.nav-item.active {
  border-bottom: 2px solid #ef5f36;
}
.nav-item.active a {
  color: #ef5f36 !important;
}
.nav-item .nav-link img {
  filter: grayscale(100%);
}
.nav-item.active .nav-link img {
  filter: grayscale(0%);
}
.previewlink {
  color: #ff283a !important;
  text-decoration: underline;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
  font-size: 15px !important;
  display: inline-block;
}

.insideTab {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 10px !important;
  }
}
