.email-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(4px);
  background-position: center center;
}

.fa-envelope {
  color: #ff283a;
  font-size: 2.5rem !important;
}

.email-verification-form {
  width: 500px;
  background-color: #000000;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.1411764705882353);
  border-radius: 1.3rem;
  padding: 30px;
  color: #fff;
}

.email-verification-text {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  background-color: #fafafa;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.1411764705882353);
  border-radius: 1.3rem;
  padding: 20px;
  color: #000;
}

.btn-submit {
  background-color: #ff283a;
  border-color: #ff283a;
  color: #fff;
}

.grad-logo {
  height: 40px;
  width: auto;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.email-verification-form.shake {
  animation: shake 0.5s;
}

/* Media Queries */

@media screen and (max-width: 480px) {
  .email-verification-form {
    width: 95%;
  }

  .email-verification-form h2 {
    font-size: 17pt;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .email-verification-form {
    width: 95%;
  }

  .email-verification-form h2 {
    font-size: 17pt;
  }
}
