@import "../../utils/Colors/index.css";

.opportunities-card-btn {
  background-color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor) !important;
  color: #fff !important;
  padding: 8px 14px !important;
  border-radius: 8px !important;
}
.opportunities-card-btn:hover {
  background-color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor) !important;
  color: #fff !important;
  padding: 8px 14px !important;
  border-radius: 8px !important;
}
.opportunities-bottom button {
  border-radius: 20px;
}
.opportunities-card {
  margin-bottom: 25px;
  justify-content: center;
}

.opportunities-card img {
  padding: 20px;
  /* object-fit: contain; */
  /* height: 155px; */
  width: 100%;
  border-radius: 28px;
}

.cardBottom {
  display: flex;
  justify-content: space-between;
  margin-right: 7px;
  margin-top: 20px;
  bottom: 32px;
  position: absolute;
  width: 88%;
}
.days-left {
  background-color: var(--thirdFontColor);
  display: block;
  padding: 7px 16px 5px 17px;
  color: #ff6002;
  border: 2px solid #ff6002;
  border-radius: 8px;
  height: 40px;
  /* margin-top: 9px; */
}

.ant-tabs-content-top a {
  color: #5736ba;
}

.opportunities-card .card {
  position: initial;
  border-radius: 12px;
  height: 320px;
}
/* .opportunities-card-btn:hover{background: #5736ba !important;}p */
.view-all-btn {
  font-size: 16px;
  background: #079ae0;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50px !important;
}
.view-all-btn:hover {
  background-color: #ff9900;
  border-color: #ff9900;
}
.date-show {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 5px;
  /* text-transform: uppercase; */
  color: #191f23;
}
.registerState .form-control {
  height: 40px;
}
.registerState input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 10px;
  color: #383838;
}
.opp-next {
  margin-right: -184px;
  margin-top: -10px;
}
.oppcardswipe .swiper-paginations {
  bottom: 3px !important;
  width: auto !important;
  display: inline-block;
  position: relative;
  margin-right: 12px;
  margin-left: 12px;
}
.oppcardswipe .swiper-btn-prev,
.oppcardswipe .swiper-btn-next {
  position: inherit;
}
.swiper-paginations {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-paginations .swiper-pagination-bullet {
  width: 24px;
  border-radius: 0;
  height: 2px;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.no-banner {
  height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgray;
}