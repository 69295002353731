.tooltip-container {
  position: relative;
  display: inline-block;
  z-index: 999;
}

.tooltip-icon {
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
}

.tooltip-text {
  visibility: hidden;
  width: 260px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 1.3;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
