@import "../../../utils/Colors/index.css";
.Opportunities {
  background-repeat: no-repeat;
  border-radius: 5px;
  text-align: center;
  background-size: 100% 100%;
}
.card-icon {
  width: 70px;
}
.Opportunitie p {
  color: white;
}
.boxcss {
  padding: 20px;
  box-shadow: 50px #e3e3e3;
  box-shadow: 0px 2px 4px #00000024;
  height: 225px;
  border-radius: 15px;
  margin-bottom: 35px;
  background: #fff;
  border: 1px solid #000;
}

.boxcss:hover {
  transition: 0.5s;
}
.boxcss img {
  margin-bottom: 12px;
  margin-top: 20px;
  transition: 0.5s;
}
.card-subtitle {
  color: var(--secondyFontColor) !important;
  font-size: var(--primaryFontSize) !important;
  font-weight: var(--secondFontWeight) !important;
  padding-top: 15px;
}
.boxcss .card-title {
  color: var(--secondyFontColor) !important;
  font-size: var(--primaryFontSize);
  font-weight: 400;
}
.boxescss {
  text-align: center;
  padding-bottom: 28px;
  transition: transform 0.2s;
}

.boxescss > span {
  font-family: "Poppins", sans-serif;
}

.box1:hover {
  background-color: #f79f92;
  color: #fff;
}
.box2:hover {
  background-color: #d0b6f1;
  color: #fff;
}
.box3:hover {
  background-color: #f9b7ae;
  color: #fff;
}
.box4:hover {
  background-color: #72bdbe;
  color: #fff;
}
.box5:hover {
  background-color: #eec979;
  color: #fff;
}
.box1:hover div .card-icon {
  transform: scale(1.5);
}
.box2:hover div .card-icon {
  transform: scale(1.5);
}
.box3:hover div .card-icon {
  transform: scale(1.5);
}
.box4:hover div .card-icon {
  transform: scale(1.5);
}
.box5:hover div .card-icon {
  transform: scale(1.5);
}
