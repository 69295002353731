/* Modal Styles Starts */
.eligibility-container {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 50vh;
  min-height: 50vh;
  height: 50vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d1d0d0;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
}

.sidebar {
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  width: 350px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar-item:not(:first-child) {
  margin-left: 15px;
}

.sidebar-item.active {
  background-color: #f9dcdc;
}

.icon-container {
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.eligibility-container {
  .main-content {
    flex-grow: 1;
    padding: 20px;
    box-shadow: none;
    height: 100%;
  }
}

.section-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.target-audience {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.option:hover {
  background-color: #f9dcdc;
}

.option.active {
  background-color: #f9dcdc;
  border: 1px solid #ff283a;
}

.eligibility-modal-footer {
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;

  button {
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 16px;
  }

  .cancel-btn {
    background-color: white;
    border: 1px solid #ff283a;
    color: #ff283a;
  }

  .cancel-btn:hover {
    background-color: #f7d2d1;
    color: #bc0404;
  }

  .save-btn {
    background-color: #ff283a;
    border: 1px solid #ff283a;
    color: white;
  }

  .save-btn:hover {
    background-color: #b70202;
  }

  .save-btn:disabled {
    background-color: #ccc;
    border: 1px solid #ccc;
    color: white;
    cursor: not-allowed;
  }
}

/* Modal Styles Ends */

/* Professional Options Styles Starts */
.professional-options-container {
  border: 1px solid #a4a4a4;
  border-radius: 5px;
  margin-top: 10px;
  background-color: white;

  p,
  label {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: black !important;
  }

  .professional-open-to-all-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .professional-experience-div {
    padding: 10px;
    border-top: 1px solid #a4a4a4;

    .experience-input-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      .experience-input-container-item {
        width: 50%;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #dcdcdc;
        border-right: none; /* Remove right border for joined effect */
        border-radius: 0; /* Remove rounded corners */
        padding: 8px 12px;
        box-sizing: border-box;
      }

      span:first-child {
        border-top-left-radius: 5px; /* Add rounded corner for the top left */
        border-bottom-left-radius: 5px; /* Add rounded corner for the bottom left */
      }

      span:first-child.error {
        border: 1px solid red;
      }

      span:last-child {
        border-right: 1px solid #dcdcdc; /* Add right border for the last span */
        border-top-right-radius: 5px; /* Add rounded corner for the top right */
        border-bottom-right-radius: 5px; /* Add rounded corner for the bottom right */
      }

      span:last-child.error {
        border: 1px solid red;
      }

      label {
        font-size: 14px;
        color: #333;
      }

      input[type="number"] {
        border: none;
        outline: none;
        font-size: 14px;
        width: 85%;
        text-align: start;
        color: #333;
      }
    }
  }
}

/* Professional Options Styles Ends */

/* School Students Options Styles Starts */
.school-students-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

/* School Students Options Styles Ends */

/* College Students Options Styles Starts */
.college-students-options-container {
  height: 100%;
  .courses-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 1px solid #d7d6d6;

    .tab {
      font-size: 14px;
      font-weight: 600;
      color: #d58787;
      cursor: pointer;
      padding-bottom: 10px;

      &.active {
        color: #e20e0e;
        border-bottom: 2px solid #e20e0e;
      }
    }
  }

  .tab-content {
    display: flex;
    height: calc(100% - 30px);

    .stream-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 35%;
      overflow-y: auto;
      border-right: 1px solid #d7d6d6;
      height: 100%;
      padding: 15px 0px;

      .stream-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        gap: 5px;
        border: 1px solid #a5a4a4;
        border-radius: 5px;
        margin-right: 10px;

        p {
          margin: 0px !important;
          width: 90%;
        }

        &.selected {
          border: 1px solid #ff283a;

          p {
            color: #b70202 !important;
          }
        }

        &.active {
          background-color: #f9dcdc;
          border: 1px solid #ff283a;
        }

        input[type="checkbox"] {
          width: 15px;
          height: 15px;
        }
      }
    }

    .stream-configs {
      width: 65%;
      height: 100%;
      overflow-y: auto;
      padding: 15px;

      p {
        font-size: 14px;
        font-weight: 600;
        color: #2e2e2e;
      }

      .specialization-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
      }
    }
  }
}
/* College Students Options Styles Ends */

/* Common Styles Starts */
.check-option {
  position: relative;
  min-width: 100px;
  min-height: 50px;
  width: max-content;
  height: max-content;
  padding: 10px;
  border: 2px solid #878787;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.check-option:hover {
  background-color: #f9dcdc;
}

.check-option.selected {
  background-color: #f9dcdc;
  border: 2px solid #ff283a;
}

.green-tick {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: green;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.others-text {
  color: rgb(112, 112, 112);
  font-size: 14px !important;
  font-weight: 500 !important;
  font-style: italic;
}

/* Common Styles Ends */
