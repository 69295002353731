.logo_sec {
  padding: 40px;
  text-align: center;
}

.menu_icon {
  float: left;
  cursor: pointer;
  padding-top: 5px;
}

.nav_items {
  padding: 25px 0;
}

.navitem_icon {
  display: flex;
  justify-content: space-between;
  /* padding: 0 25px; */
  font-size: 16px;
}

.leftsidebar_item img {
  float: left;
  width: 21px;
  margin-right: 12px;
}

.leftsidebar_item {
  padding: 17px 12px 17px 14px;
  display: block;
}

.nav_items a {
  color: var(--whiteColor);
}

/* .nav_items a:first-child {
    color: var(--oceanGreen);
    background-color: var(--lightNavyBlue);
} */

.left_sidebar_shrink .search_sec_dark {
  display: none;
}

.left_sidebar_shrink .navitem_icon {
  transition: 0.5s;
  opacity: 0;
  height: 0;
}

.setting_subs a {
  color: #fff;
}

.setting_subs {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-top: 30px;
}
.selected {
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item.ant-menu-item-selected {
  background-color: #ff283a33;
  color: #ff283a !important;
  border-radius: 8px;
}
.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #fff;
  color: #ff283a !important;
  border-radius: 8px;
  border: 2px solid red;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  margin: 0 auto;
}
.ant-menu-item {
  margin-left: 16px;
  margin-right: 16px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-submenu {
  margin-left: 0;
  font-size: 16px;
  padding: 0 12px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 25px 0 0 !important;
}
.ant-menu-sub.ant-menu-inline {
  padding-right: 42px;
  margin-left: 24px;
}
.ant-menu-submenu-open .ant-menu-inline .ant-menu-item {
  padding-left: 15px !important;
}
/* .ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-inline .ant-menu-item
{
    border: 2px solid red;
    border-radius: 5px;
    color: red;
} */
.ant-menu-sub.ant-menu-inline {
  background-color: none;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.ant-menu-submenu-open .ant-menu-submenu-title {
  background-color: #ff283a33;
  color: #ff283a !important;
  border-radius: 8px;
}
