.search-input-workshop {
  width: 100%;
  font-size: 18px;
  outline: none;
  border: none;
  padding-left: 15px;
  margin-right: 10px;
  background-color: #e9f0fa;
}
.search-icon-workshop {
  text-align: center;
  align-items: center;
  width: 100px;
  padding-right: 0px !important;
  height: 50px;
  color: #ffffff;
  background-color: #ed3836;
  border-radius: 30px;
}
.search-icon-workshop svg {
  margin-right: 0px;
  justify-items: center;
  margin-top: 15px;
}

.showingFilter {
  left: 0px !important;
  transition: 0.7s;
}

.reg-form-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin: 0;
}

.reg-form-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #ff283a; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@media screen and (min-width: 481px) {
  .filterCloseBtn {
    display: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
