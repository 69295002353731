.field-label-container label {
  color: #51636f;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin: 0px !important;
}

h4 {
  margin-bottom: 20px;
}

h4.link-heading {
  color: blue;
  cursor: pointer;
}

.registration-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 25px;
}

.input-field {
  flex: 1 1 calc(50% - 16px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  margin-top: 10px;
}

.input-field.full-width {
  flex: 1 1 100%;
}

.radio-field {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-evenly;
}

.radio-field.column {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}

.radio-field label {
  color: black;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.radio-field.institute label {
  font-size: 14px;
}

.radio-field input[type="radio"] {
  height: 15px;
  width: 15px;
}

.required-mark {
  color: red !important;
}

.add-field-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.add-field-button {
  padding: 10px;
  color: white;
  font-size: 16px;
  background-color: #ff283a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-field-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  max-height: 80vh;
  overflow-y: auto;
}

.custom-field-modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 20px;
}

.custom-field-modal-body label {
  margin-top: 10px;
}

.custom-field-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.close-modal-btn {
  padding: 8px;
  color: rgb(41, 41, 41);
  font-size: 16px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  cursor: pointer;
}

.form-control.is-mandatory {
  height: 20px;
  width: 20px;
  margin-top: 0px !important;
  position: relative;
}

.form-control:disabled {
  background-color: #e3e2e2 !important;
  cursor: not-allowed;
}

.is-mandatory-field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 20px;
}

.custom-field-option {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;
}

.custom-field-option-icon {
  height: 40px;
  width: 40px;
  color: #ff283a;
}

.institute-suggestion-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  list-style: none;
  padding: 0;
  margin-bottom: 5px;
  max-height: 116px;
  overflow-y: auto;
  z-index: 1;
}

.institute-suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.institute-suggestion-item:hover {
  background-color: rgb(209, 232, 244);
}

.custom-field-checkbox-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 14px;
  flex-wrap: wrap;

  .tnc-text {
    display: flex;
    align-items: center;
    margin-left: 10px;

    p {
      margin-bottom: 0px;
    }
  }
}

.form-control.custom-field-checkbox {
  height: 20px;
  width: 20px;
  margin: 0px !important;
  position: inherit;
}

.custom-field-checkbox-container label {
  color: black;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.delete-option-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.add-option-btn {
  background-color: #ff283a;
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
  border: none;
  margin-top: 5px;
  align-self: center;
}

.field-label-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 15px;
  margin-bottom: 0.5rem;
}

.delete-field-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.delete-field-icon :hover {
  color: #ff283a;
}

.reg-form-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin: 0;
}

.reg-form-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #ff283a;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.input-hidden {
  display: none;
}

.main-registration-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 30px;
}

#team-name-field-container {
  margin-bottom: 0px !important;
}

.prizetittle.member::after {
  left: 15%;
}

.add-team-member-btn {
  border: none;
  border-radius: 2px;
  background-color: #fcb8be;
  color: #57030a;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.add-team-member-btn:hover {
  font-weight: bold;
}

.remove-team-member-btn-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.remove-team-member-btn {
  border: none;
  border-radius: 5px;
  background-color: #ff283a;
  color: white;
  padding: 8px;
}

.remove-team-member-btn:disabled {
  background-color: #ccc;
}

.remove-team-member-btn:hover {
  font-weight: 700;
}

.custom-reg-form-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px !important;
}

.custom-reg-form.save-hosting-btn {
  margin: 0px !important;
}

.institute-list-dropdown {
  width: 100%;
  height: 48px;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  padding: 6px;
  font-size: 16px;
}

.ant-select-disabled {
  background-color: #e3e2e2 !important;
}

.ant-select-selection-item {
  color: #333333;
}

.same-org-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: small !important;
  font-style: italic !important;
}

.custom-field-modal-body .ck-editor {
  width: 100% !important;

  ul {
    li {
      list-style: square !important;
    }
  }

  ol {
    li {
      list-style: decimal !important;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
