.category-heading p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #303030;
}
.opportunities-Leftside {
  /* box-shadow: 0px 2px 20px #00000024;
    margin-bottom: 40px;
    background: #fff; */
}
.ol-body {
  width: 200px;
  padding: 40px;
  border: 1px solid;
  -moz-box-shadow: 0px 0px 10px 0px #ccc;
  -webkit-box-shadow: 0px 0px 10px 0px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
}

.ol-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9f0fa;
  margin: 30px 25px 20px 25px;
  padding: 5px 10px;
  border-radius: 5px;
}
#dropdown-basic-button {
  background-color: transparent;
  border: none;
  color: black;
}
.ol-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #ed3836; */
  border-bottom: 1px solid #cccccc87;
  padding: 10px 20px;
  width: 210px;
}
.wb-leftside {
  border-right: 1px solid #cccccc87;
}
.ol-filter .category-heading p {
  font-weight: 400 !important;
}
.form-check-block {
  display: block !important;
}

.clear-all {
  font-size: 10px;
  height: 30px;
  font-weight: 600;
  background-color: #0797db;
  color: white;
  border: none;
  border-radius: 20px;
  width: 75px;
}
.ol-status {
  border-bottom: 1px dashed #dadada;
  padding: 25px 10px 29px 15px;
  width: 210px;
}

.ol-payment {
  border-bottom: 1px dashed #eaeaea;
  margin: 10px 25px;
  padding: 5px 10px;
}

.ol-eligible {
  border-bottom: 1px dashed #eaeaea;
  margin: 10px 25px;
  padding: 5px 10px;
}

.ol-domain {
  margin: 10px 25px;
  padding: 5px 10px;
  border-bottom: 1px dashed #eaeaea;
  margin-bottom: 40px;
}
.form-check-input {
  color: #0797db;
}
Form {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1;
  font-weight: 400;
  color: #b3b3b3;
}
/* .ol-status Form{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
} */
.ol-payment Form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cstm-checkbox .category-heading p {
  margin-bottom: 5px;
}

/*Checkboxes styles*/
.cstm-checkbox input[type="checkbox"] {
  display: none;
}

.cstm-checkbox input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  font:
    14px/20px "Open Sans",
    Arial,
    sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.cstm-checkbox input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

.cstm-checkbox input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #874cf6;
  position: absolute;
  left: 0;
  top: 2px;
  opacity: 0.6;
  -webkit-transition:
    all 0.12s,
    border-color 0.08s;
  transition:
    all 0.12s,
    border-color 0.08s;
}

.cstm-checkbox input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: 0px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cstm-checkbox .form-check {
  margin-right: 20px;
}

/* radio styling */
.cstm-checkbox [type="radio"]:checked,
.cstm-checkbox [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.cstm-checkbox [type="radio"]:checked + label,
.cstm-checkbox [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.cstm-checkbox [type="radio"]:checked + label:before,
.cstm-checkbox [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #d0d0d0;
  border-radius: 100%;
  background: #fff;
}
.cstm-checkbox [type="radio"]:checked + label:after,
.cstm-checkbox [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #874cf6;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.cstm-checkbox [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.cstm-checkbox [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.eligibility .form-check {
  display: block;
}

.filterCloseBtn {
  float: right;
  padding-top: 1px;
}

@media screen and (max-width: 480px) {
  .workshop-text {
    margin-bottom: 40px !important;
    font-size: 17px !important;
  }
  .ol-filter {
    display: block !important;
  }
}
.filterHeader {
  display: flex;
  justify-content: space-between;
}
.filterHeader span {
  border-top: 3px solid;
  width: 20px;
  margin-top: 12px;
}
.category-heading {
  /* padding-right: 40px; */
}
