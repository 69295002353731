/* General Gallery Container */
.custom-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Gallery Item */
.gallery-item {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Hover Effect for Gallery Items */
.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}


/* Ligh Gallery Package CSS */
.lg-content {
  top: 72px !important;
}

.lg-toolbar .lg-close {
  position: absolute;
  top: 80px;
  right: 15px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 999;
}

.lg-toolbar .lg-close:hover {
  color: #ff4c4c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-item img {
    width: 100%;
    height: auto; /* Allow dynamic height for smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery-item {
    margin: 5px;
  }
}
